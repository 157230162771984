// @ts-nocheck
import { FunctionComponent, useMemo, CSSProperties } from "react";
import './styles.css'

type DonationFormContainer1Type = {
  formDimensions?: string | JSX.Element;
  formType?: string;
  propWidth?: CSSProperties["width"];
};

const DonationFormContainer1: FunctionComponent<DonationFormContainer1Type> = ({
  formDimensions,
  formType,
  propWidth,
}) => {
  const iconDonationFormsvgStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="flex-1 flex flex-col items-center justify-start gap-[30px] min-w-[211.6699981689453px] max-w-[251.6699981689453px] text-center text-lgi text-darkslategray font-inter lg:min-w-[211px] custom-card-item">
      <div className="flex flex-row items-center justify-center max-w-[253.3300018310547px]">
        {typeof formDimensions === 'string' ? (
          <img
            className="img-icons"
            alt=""
            src={formDimensions}
            style={iconDonationFormsvgStyle}
          />
        ) : (
          formDimensions
        )}
        <div className="flex flex-col items-center justify-center gap-[7px] ml-4">
          {formType?.split(' ').map((word, index, array) => {
            if (array.length > 3 && index === 1) {
              return (
                <div key={index} className="flex gap-2">
               
                  <div className={`relative ${index === 0 ? 'font-bold' : ''}`}>{word}</div>
                  <div className="relative">{array[index + 1]}</div>
                </div>
              );
            } else if (array.length > 3 && index === 2) {
              return null; // Skip rendering since it's already handled above
            } else {
              return (
                <div key={index} className={`relative ${index === 0 ? 'font-bold' : ''}`}>{word}</div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
  
  
  
  
};

export default DonationFormContainer1;
