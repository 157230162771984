import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface RouteGuardProps {
  children: ReactNode;
}

export const RouteGuard: React.FC<RouteGuardProps> = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return (
      <Navigate
        to='/'
        replace
      />
    );
  }
  return <>{children}</>;
};
