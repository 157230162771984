import { Home } from '@mui/icons-material';
import { HomeConfig } from 'Config/home.config';
import { FunctionComponent } from 'react';
const FundraiserSection: FunctionComponent = () => {
  return (
    <div className='self-stretch flex flex-row py-[100px] px-[50px] items-center justify-center bg-[url(/public/section@3x.png)] bg-cover bg-no-repeat bg-[top] text-center text-10xl text-dimgray-200 font-inter lg:pt-[50px] lg:pb-[50px] lg:box-border md:pt-[50px] md:pb-[50px] md:box-border'>
      {/* <div className="w-[1600px] flex flex-col items-center justify-start gap-[50px] lg:flex-1 md:flex-col">
        <div className="self-stretch flex flex-row items-start justify-center md:flex-col">
          <div className="flex-1 flex flex-row items-start justify-center lg:flex-1 md:flex-col md:flex-[unset] md:self-stretch">
            <div className="flex-1 flex flex-col items-center justify-start gap-[20px] md:flex-col md:flex-[unset] md:self-stretch">
              <h2 className="m-0 relative text-[inherit] font-light font-inherit sm:text-3xl">
                {HomeConfig.section2.title}
              </h2>
              <button className="cursor-pointer py-[9.190000534057617px] pr-[18.839996337890625px] pl-[19px] bg-[transparent] rounded-81xl flex flex-row items-start justify-center border-[3px] border-solid border-slategray">
                <b className="relative text-mini uppercase font-inter text-slategray text-center">
                  Request a Demo
                </b>
              </button>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center gap-[135px] text-smi text-olivedrab lg:gap-[50px] md:flex-col md:gap-[40px] md:items-center md:justify-center">
          {HomeConfig.section2.items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-start"
            >
              <div className="relative rounded-3xl bg-white shadow-[0px_0px_24px_rgba(57,_65,_70,_0.3)] w-80 h-[600px]">
                <div className="absolute w-full top-[-0.5px] right-[0px] left-[0px] rounded-t-2xl rounded-b-none bg-whitesmoke h-[140px] flex flex-col pt-[21px] pb-[77px] pr-[38.65999984741211px] pl-[37.34000015258789px] box-border items-center justify-start">
                  <b className="relative leading-[16px]">{item.mainTitle}</b>
                  <b className="relative text-xs leading-[21px] text-dimgray-200">
                    {item.subtitle}
                  </b>
                </div>
                <br></br>
                <div className="absolute w-full top-[209.5px] right-[0px] left-[0px] flex flex-col pt-5 pb-[54.25px] pr-[31.6400146484375px] pl-[21px] box-border items-start justify-start gap-[9.59px] min-h-[260px] text-left text-xs text-darkslategray">
                  <div className="w-[267.36px] h-[200.16px] flex flex-col py-0 pr-[18.3599853515625px] pl-0 box-border items-center justify-center">
                    <p className="m-0 relative leading-[19.6px] font-light">
                      {item.features.map((feature) => (
                        <span className="block">{feature}</span>
                        
                      ))}
                    </p>
                  </div>
                </div>

                <div className="absolute top-[69.5px] left-[90px] rounded-51xl box-border w-[140px] h-[140px] flex flex-row p-0.5 items-start justify-start border-[2px] border-solid border-whitesmoke">
                  <div className="rounded-49xl box-border w-[136px] flex flex-row p-1 items-start justify-start border-[4px] border-solid border-whitesmoke">
                    <div className="flex flex-row items-start justify-center max-w-[136px]">
                      <img
                        className="relative w-32 h-32 overflow-hidden shrink-0"
                        alt=""
                        src="/logo.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};
export default FundraiserSection;
