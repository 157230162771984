import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import { LoginProvider } from "Contexts/AuthProvider/AuthProvider";
import { DialogProvider } from "Contexts/Modals/ModalProvider";
import { EnrollProvider } from "Contexts/EnrollProvider/EnrollProvider";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <LoginProvider>
      <EnrollProvider>
        <DialogProvider>
          <App />
        </DialogProvider>
      </EnrollProvider>
    </LoginProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
