import { useState, useRef, useEffect } from 'react';
import FormPersonal from 'components/Form/Dwolla/FormPersonal';

export default function IndividualForm({ store }: any) {
  const [data, setData] = useState(null);
  const [validate, setValidate] = useState(true);
  const [customerId, setCustomerId] = useState(null);
  const save = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [validateTwo, setValidateTwo] = useState(true);

  useEffect(() => {
    if (validate === false && isChecked === true) {
      setValidateTwo(false);
    } else {
      setValidateTwo(true);
    }
  }, [validate, isChecked]);

  return (
    <div>
      <FormPersonal
        data={data as any}
        setValidate={setValidate as any}
        save={save as any}
        setCustomerId={setCustomerId as any}
        _showMessage={() => {}}
      />
    </div>
  );
}
