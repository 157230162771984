import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Grid } from '@mui/material';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import './styles.css';
import { ipayNet, newServer } from 'constants/constants';
import { getGuid } from '../../../constants/constants';
import ConfirmationMenssage from '../ConfirmationMenssage/ConfirmationMenssage';

const ClickAgreeStep = () => {
  const { user } = useLogin();
  const [guid, setGuid] = useState('');
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGuidUsio = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${newServer}ipaynet/get-guid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({ reference: user?.uuid }),
      });

      const data: string = await response.text();
      setGuid(data);
    } catch (error) {
      setLoading(false);
    }
  };

  async function receiveMessage(event: any) {
    if (event.data == 'Complete') {
      setLoading(true);
      handleRequest();
      setComplete(true);
    }
  }

  const handleRequest = async () => {
    const response = await getInfoDwolla();
    try {
      await fetch(`${newServer}requests/status-internal/${response?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          status_usio: 7,
        }),
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getInfoDwolla = async () => {
    if (user?.user_id) {
      try {
        const getDataDwolla = await fetch(`${newServer}requests/user/${user?.user_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });
        const _jsonInfoDwolla = await getDataDwolla.json();
        if (_jsonInfoDwolla.status_usio_id === 7) {
          setComplete(true);
        }

        return _jsonInfoDwolla;
      } catch (error) {
        console.log('getInfoDwolla', error);
      }
    }
  };

  useEffect(() => {
    getGuidUsio();
    getInfoDwolla();
    setLoading(false);
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  return (
    <Grid>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '800px',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <br />
      {complete ? (
        <>
          <ConfirmationMenssage />
        </>
      ) : guid ? (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant='h5'
              align='center'
              gutterBottom
            >
              To proceed with your Merchant enrollment, please indicate where should we deposit your
              money?
            </Typography>
          </Grid>

          {/* <Grid
            item
            xs={12}
          >
            <Typography
              variant='h6'
              align='center'
              gutterBottom
            >
              To deposit money into your bank, we'll need your bank details.
            </Typography>
          </Grid> */}

          <Grid
            item
            xs={12}
            style={{ padding: '0 24px' }}
          >
            {' '}
            {/* Ajusta el padding del elemento Grid */}
            <Typography
              variant='subtitle1'
              component='div'
              style={{ textAlign: 'left', margin: '0 auto', maxWidth: '600px' }} // Establece un máximo de ancho y centrado automático
            >
              <ul style={{ paddingLeft: '20px' }}>
                {' '}
                {/* Ajusta el padding del elemento ul */}
                <li>Account Number: Double-check that your account number is correct.</li>
                <li>Routing Number: Verify this 9-digit code to ensure it matches your bank.</li>
                <li>
                  Name on Bank Account: Confirm that the name listed is exactly as it appears on
                  your bank account.
                </li>
              </ul>
            </Typography>
          </Grid>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '800px',
              width: '100%',
            }}
          >
            <iframe
              id='mi-iframe'
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                margin: '0px',
                padding: '0px',
                height: '100%',
                width: '100%',
              }}
              src={getGuid(guid)}
            ></iframe>
          </div>
        </Grid>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '800px',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default ClickAgreeStep;
