import { FunctionComponent, useMemo, type CSSProperties, ReactElement } from "react";

type FormContainerType = {
  imageDimensions?:  ReactElement;
  featureDescription?: string;
  imageDimensionsAlt?: ReactElement;
  featureSubtitle?: string;

  /** Style props */
  propMaxWidth?: CSSProperties["maxWidth"];
  propWidth?: CSSProperties["width"];
  propHeight?: CSSProperties["height"];
  propPadding?: CSSProperties["padding"];
  propBoxSizing?: CSSProperties["boxSizing"];
  propMaxWidth1?: CSSProperties["maxWidth"];
  propHeight1?: CSSProperties["height"];
};

const FormContainer: FunctionComponent<FormContainerType> = ({
  imageDimensions,
  featureDescription,
  imageDimensionsAlt,
  featureSubtitle,
  propMaxWidth,
  propWidth,
  propHeight,
  propPadding,
  propBoxSizing,
  propMaxWidth1,
  propHeight1,
}) => {
  const iconCustomerExperiencesvgStyle: CSSProperties = useMemo(() => {
    return {
      maxWidth: propMaxWidth,
    };
  }, [propMaxWidth]);

  const iconCustomerExperiencesvg1Style: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  const iconCalendar11svgStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
      boxSizing: propBoxSizing,
      maxWidth: propMaxWidth1,
    };
  }, [propPadding, propBoxSizing, propMaxWidth1]);

  const iconCalendar11svg1Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight1,
    };
  }, [propHeight1]);

  return (
    <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-center text-base text-darkslategray font-inter sm:flex-col md:flex-col sm:gap-[40px]">
      <div className="flex-1 flex flex-row items-start justify-start sm:flex-[unset] sm:self-stretch md:flex-[unset] md:self-stretch">
        <div className="flex-1 flex flex-col items-center justify-start gap-[13px]">
          <div
            className="flex flex-row py-[3px] px-0 box-border items-start justify-center max-w-[275.9100036621094px]"
            style={iconCustomerExperiencesvgStyle}
          >
            <div
              className="relative w-16 h-[58px] overflow-hidden shrink-0"
              style={iconCustomerExperiencesvg1Style}
            >
             {imageDimensions}

            </div>
          </div>
          <div className="relative leading-[25.2px]">{featureDescription}</div>
        </div>
      </div>
      <div className="flex-1 flex flex-row items-start justify-start sm:flex-[unset] sm:self-stretch md:flex-[unset] md:self-stretch">
        <div className="flex-1 flex flex-col items-center justify-start gap-[13px]">
          <div
            className="flex flex-row items-start justify-center max-w-[275.9100036621094px]"
            style={iconCalendar11svgStyle}
          >
            <div
              className="relative w-16 h-16 overflow-hidden shrink-0"
              style={iconCalendar11svg1Style}
            >{imageDimensionsAlt}</div>
          </div>
          <div className="relative leading-[25.2px]">{featureSubtitle}</div>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
