import { HomeConfig } from 'Config/home.config';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

const CardContainer1: FunctionComponent = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/enroll');
  };

  return (
    <div
      className='self-stretch h-[689px] flex flex-row pt-[20px] px-[50px] pb-[100px] box-border  public/fondo/footer.pngitems-start justify-center  bg-cover bg bg-no-repeat bg-[top] text-center text-14xl text-white font-inter md:h-auto md:items-center md:justify-start md:pt-20 md:pb-[100px] md:box-border sm:h-auto sm:items-center sm:justify-start sm:pt-20 sm:pb-[200px] sm:box-border'
      style={{ backgroundImage: `url(${HomeConfig.section3.bannerImg})` }}
    >
      <div className='w-[1600px] flex flex-col items-center justify-start lg:flex-1 md:flex-col md:gap-[10px] md:items-center md:justify-center sm:h-auto'>
        <div className='self-stretch flex flex-row items-start justify-center md:flex-col md:items-center md:justify-center sm:h-auto'>
          <div className='flex-1 flex flex-row items-start justify-center lg:flex-1 md:flex-col md:items-center md:justify-center md:flex-[unset] md:self-stretch sm:h-auto sm:flex-col sm:gap-[0px] sm:pt-0 sm:pb-0 sm:box-border'>
            <div className='flex-1 flex flex-col items-center justify-start gap-[20px] md:flex-col md:gap-[10px] md:items-center md:justify-center md:flex-[unset] md:self-stretch sm:h-auto sm:flex-[unset] sm:self-stretch'>
              <div className='relative tracking-[-0.96px] leading-[57.6px] font-light md:text-3xl sm:text-lgi sm:leading-[24px] sm:self-stretch sm:w-auto sm:flex-1'>
                {HomeConfig.section3.title}
                <div className='flex-1 relative text-mini font-medium font-inter text-white text-center w-28'>
                  {/* Apply{' '} */}
                </div>
              </div>
              <div className='flex flex-row items-center justify-start gap-[113px] sm:self-stretch sm:w-auto sm:flex-1 sm:flex-col sm:gap-[40px]'>
                <button className='cursor-pointer [border:none] py-[26.5px] pr-[37.80999755859375px] pl-[27px] bg-[transparent] rounded-81xl [background:linear-gradient(135deg,_#fff,_rgba(255,_255,_255,_0.7)),_#fff] flex flex-row items-start justify-center sm:w-auto sm:[align-self:unset] sm:h-auto'>
                  <div
                    onClick={navigateHome}
                    className='relative text-mid uppercase font-inter text-olivedrab text-center'
                  >
                    {HomeConfig.section3.button.text}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer1;
