import InformationDialog from 'Dialogs/Information/Information';
import { LoginDialog } from 'Dialogs/Login/Login';
import ResetPasswordDialog from 'Dialogs/ResetPassword/ResetPassword';
import FileUploadButtons from 'Dialogs/UploadId/UploadId';
import FormBeneficialOwners from 'components/Form/Dwolla/FormBeneficialOwners';
import React, { createContext, useContext, useState, ReactNode, FC, useEffect } from 'react';

export type dialogTypes =
  | 'information'
  | 'reset-password'
  | 'add-user-enroll'
  | 'edit-user-enroll'
  | 'add-id-file'
  | 'login';

interface DialogContextType {
  openDialog: (name: dialogTypes, ModalComponent?: ReactNode | FC, data?: any) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>({
  openDialog: (name: string, a: any, b: any) => {},
  closeDialog: () => {},
});

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

interface DialogProviderProps {
  children: React.ReactNode;
}

export const DialogProvider: FC<DialogProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any>({});
  const [modalName, setModalName] = useState<any>();
  const [ModalComponent, setModalComponent] = useState<FC | any>(null);
  const [modalKey, setModalKey] = useState(0);

  const openDialog = (name: dialogTypes, dialogModalComponent: ReactNode | FC, data: any) => {
    setData(data);
    // setModalComponent(dialogModalComponent);
    setIsOpen(true);
    setModalName(name);
    setModalKey((prevKey) => prevKey + 1);
  };

  const closeDialog = () => {
    setData(null);
    setModalComponent(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setModalComponent(
        <InformationDialog
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      );
    } else {
      setModalComponent(null);
    }
  }, [isOpen, data]);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      {isOpen && modalName === 'information' && (
        <InformationDialog
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      )}

      {isOpen && modalName === 'reset-password' && (
        <ResetPasswordDialog
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      )}

      {isOpen && modalName === 'add-user-enroll' && (
        <FormBeneficialOwners
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      )}
      {isOpen && modalName === 'edit-user-enroll' && (
        <FormBeneficialOwners
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      )}
      {isOpen && modalName === 'add-id-file' && (
        <FileUploadButtons
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
          data={data}
        />
      )}
      {isOpen && modalName === 'login' && (
        <LoginDialog
          key={modalKey}
          open={isOpen}
          onClose={closeDialog}
        />
      )}
    </DialogContext.Provider>
  );
};
