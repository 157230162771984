import { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Container,
  Grid,
} from '@mui/material';

import './styles.css';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import { CheckCircle, Delete, Edit, Person, Photo } from '@mui/icons-material';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { updateEnroll } from 'Helpers/update-enroll';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isMobile;
};

const FormValidation = () => {
  const [users, setUsers] = useState<any>([]);
  const { openDialog } = useDialog();
  const [validatebeneficial, setValidateBeneficial] = useState(true);
  const [hidenbeneficial, setHidenBeneficial] = useState(true);
  const [beneficials, setBeneficials] = useState([{}]);
  const saveInDwolla = useRef(null);
  const saveInMap = useRef(null);
  const save = useRef(null);
  const { user } = useLogin();
  const { enrollData, saveEnrrollData, enrollDataUsio, saveEnrrollDataUsio } = useEnrollForm();
  const isSoleUser = enrollData?.data.businessType === 'Sole Proprietorship';
  const isMobile = useMobileScreen();

  useEffect(() => {
    if (enrollData?.data?.owners?.length) {
      setUsers([enrollData.data, ...enrollData?.data?.owners]);
    } else {
      setUsers([enrollData.data]);
    }

    if (enrollData?.data?.controller?.ownershipPercent) {
      if (Number(enrollData?.data?.controller?.ownershipPercent) === 100) {
        setHidenBeneficial(false);
      }

      let totalOwnershipPercent = 0;

      if (enrollData.data.controller) {
        totalOwnershipPercent += parseFloat(enrollData.data.controller.ownershipPercent);

        enrollData.data.owners.forEach((owner: { ownershipPercent: string }) => {
          totalOwnershipPercent += parseFloat(owner.ownershipPercent);
        });
      }

      if (totalOwnershipPercent === 100) {
        setHidenBeneficial(false);
      }
    }

    setBeneficials([enrollData.data]);
  }, [enrollData]);

  const showModal = () => {
    openDialog('add-user-enroll', null, {
      title: 'Add a Beneficiary',
      description: 'User was created successfully',
      setValidateBeneficial,
      validatebeneficial,
      saveInDwolla,
      saveInMap,
      save,
      setBeneficials,
      saveNewBenefitial,
      beneficials,
    });
  };

  const openAddIdModal = (ssn: any) => {
    openDialog('add-id-file', null, {
      user,
      enrollData,
      ssn,
    });
  };

  const saveNewBenefitial = async (benefitial: any) => {
    setUsers([...users, benefitial]);
    let ownersUsio: any = [];
    const owners = users.slice(1);
    owners.push(benefitial);

    owners?.map((item: any) => {
      ownersUsio.push({
        firstName: item.firstName,
        lastName: item.lastName,
        title: 'Owner',
        addressLine1: item.address.address1,
        city: item.address.city,
        state: item.address.stateProvinceRegion,
        postalCode: item.address.postalCode,
        phone: '9999999999',
        dob: item.dateOfBirth,
        last4SSN: item.ssn,
        ownershipPercent: item.ownershipPercent.toString(),
      });
    });

    try {
      await updateEnroll(user, {
        user_id: user?.user_id,
        info_dwolla: { ...enrollData.data, owners: owners },
        info_usio: { ...enrollDataUsio.data, owners: ownersUsio },
      });

      saveEnrrollData({ ...enrollData.data, owners: owners });
      saveEnrrollDataUsio({ ...enrollDataUsio.data, owners: ownersUsio });
    } catch (error) {
      console.log('updateEnroll', error);
    }
  };

  const deleteOwners = async (ssn: any) => {
    const owners = users.slice(1);
    const ownersUsio = enrollDataUsio.data.owners.slice(1);
    const newOwners = owners.filter((item: any) => item.ssn !== ssn);
    const newOwnersUsio = ownersUsio.filter((item: any) => item.last4SSN !== ssn);
    try {
      await updateEnroll(user, {
        user_id: user?.user_id,
        info_dwolla: { ...enrollData.data, owners: newOwners },
        info_usio: { ...enrollDataUsio.data, owners: newOwnersUsio },
      });

      saveEnrrollData({ ...enrollData.data, owners: newOwners });
      saveEnrrollDataUsio({ ...enrollDataUsio.data, owners: newOwnersUsio });
      setHidenBeneficial(true);
    } catch (error) {
      console.log('updateEnroll', error);
    }
  };

  const openEditIdModal = async (ssn: any) => {
    const owners = users.slice(1);
    const owner = owners.filter((item: any) => item.ssn === ssn);
    openDialog('add-user-enroll', null, {
      title: 'Edit Beneficial',
      description: 'Edit Beneficial',
      setValidateBeneficial,
      validatebeneficial,
      saveInDwolla,
      saveInMap,
      save,
      setBeneficials,
      saveNewBenefitial,
      beneficials,
      owner: owner[0],
    });
  };

  return (
    <Card>
      <CardContent>
        {isMobile ? (
          <div className='card-container'>
            {users.map((user: any, index: any) => (
              <div
                className='card'
                key={index}
              >
                <div className='card-content'>
                  <h4>First Name: {user.firstName}</h4>
                  <h4>Last Name: {user.lastName}</h4>
                  <p>Date Of Birth: {user.dateOfBirth}</p>
                  <p>Title: {user.controller?.title || 'N/A'}</p>
                  <p>
                    Percentage:{' '}
                    {user?.ownershipPercent || user?.controller?.ownershipPercent || 100}%
                  </p>
                  <button
                    onClick={() => openAddIdModal(user.ssn ? user.ssn : user?.controller?.ssn)}
                  >
                    {user?.userIdImgs ? <CheckCircle style={{ color: 'green' }} /> : <Photo />}
                  </button>
                  <button
                    onClick={() => openEditIdModal(user.ssn ? user.ssn : user?.controller?.ssn)}
                  >
                    {<Edit style={{ color: 'green' }} />}
                  </button>
                  <button
                    onClick={() => {
                      deleteOwners(user.ssn ? user.ssn : user?.controller?.ssn);
                    }}
                  >
                    {<Delete style={{ color: 'red' }} />}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'> First Name</TableCell>
                  <TableCell align='center'>Last Name</TableCell>
                  <TableCell align='center'>Date Of Birth</TableCell>
                  <TableCell align='center'>Title</TableCell>
                  <TableCell align='center'>Percentage</TableCell>
                  <TableCell align='center'>Edit</TableCell>
                  <TableCell align='center'>Delete</TableCell>
                  <TableCell align='center'>Document</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell align='center'>{user.firstName}</TableCell>
                    <TableCell align='center'>{user.lastName}</TableCell>
                    <TableCell align='center'>{user.dateOfBirth}</TableCell>
                    <TableCell align='center'>
                      {user.controller?.title ? user.controller?.title : 'N/A'}
                    </TableCell>
                    <TableCell align='center'>
                      {user?.ownershipPercent || user?.controller?.ownershipPercent || 100}%
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          openEditIdModal(user.ssn ? user.ssn : user?.controller?.ssn);
                        }}
                        disabled={index === 0} // Disable button if index is 0
                      >
                        {<Edit style={{ color: index === 0 ? 'grey' : 'green' }} />}
                      </Button>
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          deleteOwners(user.ssn ? user.ssn : user?.controller?.ssn);
                        }}
                        disabled={index === 0} // Disable button if index is 0
                      >
                        {<Delete style={{ color: index === 0 ? 'grey' : 'red' }} />}
                      </Button>
                    </TableCell>
                    <TableCell align='center'>
                      <Button
                        onClick={() => {
                          openAddIdModal(user.ssn ? user.ssn : user?.controller?.ssn);
                        }}
                      >
                        {user?.userIdImgs ? <CheckCircle style={{ color: 'green' }} /> : <Photo />}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isSoleUser && hidenbeneficial && (
          <Container
            maxWidth='sm'
            className='user-add-container'
          >
            <Grid
              container
              spacing={1}
              alignItems='center'
              justifyContent='center'
              className='user-add-content'
            >
              <Grid
                item
                xs={12}
              >
                <Grid
                  container
                  justifyContent='center'
                >
                  <Grid item>
                    <Person
                      style={{ fontSize: 70, backgroundColor: '#ff0099' }}
                      className='add-icon'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant='h6'
                  align='center'
                  className='add-user-title'
                >
                  Add new beneficiary
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Grid
                  container
                  justifyContent='center'
                >
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<Person />}
                      className='add-user-button'
                      style={{ backgroundColor: '#ff0099' }}
                      onClick={showModal}
                    >
                      Add beneficiary
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
      </CardContent>
    </Card>
  );
};

export default FormValidation;
