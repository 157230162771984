import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Icon,
  Box,
  LinearProgress,
  Fab,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  AssignmentInd as SelectTypeIcon,
  Edit as CompleteFormIcon,
  CheckCircle as ValidateIcon,
  Business,
  Person,
  ArrowLeft,
  ArrowRight,
  Info,
  MoneyOutlined,
  Send,
} from '@mui/icons-material'; // Import icons
import { RoundedButton } from 'ui/atoms/RoundedButton/RoundedButton';
import CompanyForm from 'components/CompanyForm/CompanyForm';
import IndividualForm from 'components/IndividualForm/CompanyForm';
import AuthPage from 'components/AuthForm/AuthForm';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
// import { useForm } from 'components/Form/Custom/UseForm';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { useDialog } from 'Contexts/Modals/ModalProvider';
import Lottie from 'react-lottie';
import animationData from '../../lotties-animations/animation_ln9kp054.json';
import animationDataReview from '../../lotties-animations/review.json';
import { updateEnroll } from 'Helpers/update-enroll';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import FundingStep from 'components/Steps/FundingStep/FundingStep';
import FormValidation from 'components/Steps/FormValidation/FormValidation';
import { RejectedEnrolled } from 'components/RejectedEnroll/RejectedEnrolled';
import ClickAgreeStep from 'components/Steps/ClickAgreeStep/ClickAgreeStep';
import ConfirmationMenssage from 'components/Steps/ConfirmationMenssage/ConfirmationMenssage';
import PlaidStep from 'components/Steps/PlaidStep/PlaidStep.jsx';
import MenssgePostDay from 'components/Steps/MenssagePostDay/MenssgePostDay';
import { newServer } from 'constants/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EnrollmentForm = () => {
  const [enrollmentType, setEnrollmentType] = useState('');
  const [submitionOnReview, setSubmitionOnReview] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [displayNavigationButtons, setDisplayNavigationButtons] = useState(true);
  const { user, logout } = useLogin();
  const save = useRef(null);
  const { openDialog } = useDialog();
  const { enrollData, enrollDataUsio, stateDwolla, setStateDwolla, stateUsio } = useEnrollForm();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = [
    { label: 'Select Enrollment Type', icon: <SelectTypeIcon /> },
    { label: 'Complete Form', icon: <CompleteFormIcon /> },
    { label: 'Beneficial Owners Information', icon: <CompleteFormIcon /> },
    { label: 'Setup Merchant Processor', icon: <Send /> },
    { label: 'Funding Setup', icon: <MoneyOutlined /> },
    { label: 'Finish Account Setup', icon: <ValidateIcon /> },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsReview = {
    loop: true,
    autoplay: true,
    animationData: animationDataReview,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const getInfoDwolla = async () => {
    if (user?.user_id) {
      try {
        const getDataDwolla = await fetch(`${newServer}requests/user/${user?.user_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });
        const _jsonInfoDwolla = await getDataDwolla.json();
        return _jsonInfoDwolla;
      } catch (error) {
        console.log('getInfoDwolla', error);
      }
    }
  };

  useEffect(() => {
    async function fetchAndSetInfo() {
      const result = await getInfoDwolla();
      setStateDwolla(result?.status_dwolla_id ? result?.status_dwolla_id : 0);
    }

    fetchAndSetInfo();

    if (user?.token) {
      setDisplayNavigationButtons(true);
    } else {
      setDisplayNavigationButtons(false);
      setActiveStep(0);
      return;
    }

    if (stateDwolla === 2 && stateUsio === 4) {
      setDisplayNavigationButtons(false);
      setActiveStep(5);
      return;
    }

    switch (stateDwolla) {
      case 1:
        setDisplayNavigationButtons(false);
        setActiveStep(3);
        break;

      case 2:
        setDisplayNavigationButtons(false);
        setActiveStep(4);
        break;

      case 3:
        setDisplayNavigationButtons(true);
        setActiveStep(3);
        break;

      case 9:
        setDisplayNavigationButtons(true);
        setActiveStep(9);
        break;

      case 10:
        setDisplayNavigationButtons(true);
        setActiveStep(10);
        break;

      case 12:
        setDisplayNavigationButtons(true);
        setActiveStep(12);
        break;

      case 13:
        setDisplayNavigationButtons(true);
        setActiveStep(13);
        break;

      case 16:
        setDisplayNavigationButtons(true);
        setActiveStep(16);
        break;

      default:
        // setActiveStep(0);
        break;
    }
  }, [user, stateDwolla, stateUsio]);

  const hanleSubmitForm = () => {
    let totalOwnershipPercent = 0;

    if (enrollData.data.controller) {
      totalOwnershipPercent += parseFloat(enrollData.data.controller.ownershipPercent);

      enrollData.data.owners.forEach((owner: { ownershipPercent: string }) => {
        totalOwnershipPercent += parseFloat(owner.ownershipPercent);
      });

      // Verificar si la suma es al menos 25
      if (totalOwnershipPercent >= 25) {
        // Si no hay más propietarios para agregar
        if (totalOwnershipPercent < 100) {
          openDialog('information', null, {
            title: 'Ownership Percentage Notice',
            icon: <Info />,
            cancelText: 'Ok',
            description: `The total ownership percentage meets the minimum requirement of 25%. It currently totals ${totalOwnershipPercent}%. You can still add more owners until the total reaches 100%.`,
          });
        }
      } else {
        openDialog('information', null, {
          title: 'Application Incomplete',
          icon: <Info />,
          cancelText: 'Ok',
          description: `The total percentage is less than the minimum required 25%. It currently totals ${totalOwnershipPercent}%.`,
        });
        return;
      }
    }

    // Revisa si hay imágenes en la propiedad userIdImgs del objeto info_dwolla
    if (!enrollData?.data?.userIdImgs?.length) {
      openDialog('information', null, {
        title: 'Application incomplete',
        icon: <Info />,
        cancelText: 'Ok',
        description: 'You need to upload at least one photo of your ID, passport, or license.',
      });
      return;
    }

    // Revisa si hay imágenes en la propiedad userIdImgs de cada objeto en el array owners
    for (const owner of enrollData?.data?.owners || []) {
      if (!owner.userIdImgs || owner.userIdImgs.length === 0) {
        openDialog('information', null, {
          title: 'Application incomplete',
          icon: <Info />,
          cancelText: 'Ok',
          description:
            'Each owner needs to add at least one photo of their ID, passport, or license.',
        });
        return;
      }
    }

    openDialog('information', null, {
      title: 'Submit application',
      icon: <Info />,
      cancelText: 'Cancel',
      description:
        'Are you sure you want to submit your application? Please ensure that all details are accurate before proceeding.',
      onConfirm: confirmSubmition,
    });
  };

  const confirmSubmition = async () => {
    try {
      await updateEnroll(user, {
        user_id: user?.user_id,
        is_dwolla: true,
        is_usio: true,
        info_dwolla: { ...enrollData.data },
        info_usio: { ...enrollDataUsio.data },
      });

      setActiveStep(activeStep + 1);
      setDisplayNavigationButtons(false);
    } catch (error) {
      console.log('confirmSubmition', error);
    }
  };

  const handleNext = () => {
    if (!enrollData || (enrollData.data?.isValid && activeStep === 1)) {
      return;
    }

    if (activeStep === 2) {
      hanleSubmitForm();
      return;
    }

    let currentStep = activeStep + 1;

    setActiveStep(currentStep);

    if (user?.token) {
      if (save?.current) {
        (save as any)?.current();
      }
      setDisplayNavigationButtons(true);
    } else {
      setDisplayNavigationButtons(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const navigateHome = () => {
    logout();
    navigate('/');
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <Container
            style={{
              alignItems: 'center',
              justifyContent: 'center', // Asegúrate de que todo esté centrado
              textAlign: 'center',
              paddingTop: '25px',
            }}
          >
            <Grid
              // container
              // spacing={2}
              item
              xs={12}
              md={10}
              lg={10}
            >
              {/* {false && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                >
                  <Card className='card-custom'>
                    <CardContent>
                      <Icon>
                        <Person />
                      </Icon>
                      <Typography variant='h5'>Individual</Typography>
                      <Typography variant='body2'>
                        This is the best option for freelancers and small businesses.
                      </Typography>
                      <RoundedButton
                        // disabled
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          handleNext();
                          setEnrollmentType('individual');
                        }}
                        style={{ margin: 10 }}
                      >
                        Apply
                      </RoundedButton>
                    </CardContent>
                  </Card>
                </Grid>
              )} */}
              {!user?.token && <AuthPage />}
              {user?.token && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className='center-item'
                >
                  <Card className='card-custom'>
                    <CardContent>
                      <Icon>
                        <Business />
                      </Icon>
                      <Typography variant='h5'>Organization</Typography>
                      <Typography variant='body2'>
                        If you represent an Animal Shelter, Animal Welfare Non-Profit Organization,
                        or Animal Services, this is the ideal choice for your application.
                      </Typography>
                      <RoundedButton
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          handleNext();
                          setEnrollmentType('business');
                        }}
                      >
                        Apply
                      </RoundedButton>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Container>
        );
      case 1:
        return (
          <div>
            {!user?.token && <AuthPage />}
            {user?.token && (
              <div>
                {!submitionOnReview && (
                  <div>
                    {enrollmentType === 'individual' && <IndividualForm />}
                    {enrollmentType === 'business' && (
                      <CompanyForm
                        save={save}
                        setSubmitionOnReview={setSubmitionOnReview}
                        setDataLoaded={setDataLoaded}
                        dataLoaded={dataLoaded}
                      />
                    )}
                  </div>
                )}

                {!dataLoaded && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <Card>
            <FormValidation />
          </Card>
        );
      case 3:
        return (
          <Card>
            {stateDwolla !== 3 ? (
              <div>
                {!submitionOnReview ? (
                  <CardContent>
                    <Typography
                      variant='h1'
                      align='center'
                      style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        marginTop: '25px',
                      }}
                    >
                      Application : Pending Review
                    </Typography>
                    <Typography
                      variant='h4'
                      align='center'
                      style={{ fontSize: '20px', marginTop: '10px' }}
                    >
                      You will receive an email soon with the next steps to continue the onboarding
                      process.
                    </Typography>
                    <Lottie
                      options={defaultOptions}
                      height={300}
                      width={300}
                    />
                    <Grid style={{ justifyContent: 'center', display: 'flex' }}>
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Person />}
                        className='add-user-button'
                        onClick={navigateHome}
                        style={{ backgroundColor: '#ff0099', margin: '10px' }}
                      >
                        Return Home
                      </Button>
                    </Grid>
                  </CardContent>
                ) : (
                  <Card>
                    <Grid>
                      <Box>
                        <Lottie
                          options={defaultOptionsReview}
                          height={300}
                          width={300}
                        />
                      </Box>
                      <Typography
                        variant='h4'
                        align='center'
                        style={{
                          fontSize: '15px',

                          margin: '25px',
                        }}
                      >
                        Application successfully uploaded , and it's currently under review. Expect
                        to receive an update shortly.
                      </Typography>
                    </Grid>
                  </Card>
                )}
              </div>
            ) : (
              <RejectedEnrolled />
            )}
          </Card>
        );
      case 4:
        return (
          <Card>
            <ClickAgreeStep />
          </Card>
        );
      case 5:
        return <ConfirmationMenssage />;
      case 9:
        return <PlaidStep setActiveStep={setActiveStep} />;
      case 10:
        return (
          <div style={{ textAlign: 'center' }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Card>
              <MenssgePostDay />
            </Card>
          </div>
        );
      case 12:
        return <PlaidStep setActiveStep={setActiveStep} />;
      case 13:
        return <PlaidStep setActiveStep={setActiveStep} />;
      case 16:
        return (
          <>
            <br />
            <FundingStep />
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container className='container'>
      {activeStep != 0 && (
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={({ completed }) => (
                  <div
                    style={{
                      backgroundColor: completed
                        ? 'green'
                        : index === activeStep
                        ? '#ff0099'
                        : '#ff0099',
                      color: 'white',
                      borderRadius: '50%',
                      width: isMobile ? '24px' : '36px',
                      height: isMobile ? '24px' : '36px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: isMobile ? '12px' : 'inherit',
                    }}
                  >
                    {completed ? <CheckCircleIcon /> : step.icon}{' '}
                  </div>
                )}
              >
                {isMobile ? (index === activeStep ? step.label : null) : step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <div>
        <div
        // style={{
        //   display: 'flex',
        //   marginTop: '20px',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        // }}
        >
          {displayNavigationButtons && user && (
            <Grid
              container
              spacing={2}
              style={{ justifyContent: 'center' }}
            >
              <Grid item>
                {activeStep !== 0 &&
                  activeStep !== 16 &&
                  activeStep !== 10 &&
                  activeStep !== 13 && (
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: '-webkit-right' }}
                      className='flex justify-end items-end'
                    >
                      <Fab
                        type='submit'
                        variant='extended'
                        color='primary'
                        className='p-10 border-1 bg-black rounded-3xl w-200 text-white mt-8 ml-10 d-flex align-items-center justify-content-center'
                        style={{
                          position: 'fixed',
                          bottom: '20px',
                          zIndex: 1000,
                          display: 'flex', // Asegurarse de que el contenedor es de tipo flex
                          alignItems: 'center', // Alinear elementos verticalmente al centro
                          justifyContent: 'center', // Centrar elementos horizontalmente

                          ...(isMobile
                            ? { marginLeft: 10, left: '0', width: 'calc(30% - 20px)' }
                            : { right: '130px' }), // Aplica la propiedad left solo si no es móvil
                        }}
                        onClick={handleBack}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ArrowLeft />
                          Back
                        </div>
                      </Fab>
                    </Grid>
                  )}
              </Grid>

              {activeStep !== 0 && activeStep !== 16 && activeStep !== 10 && activeStep !== 13 && (
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: '-webkit-right' }}
                  className='flex justify-end items-end'
                >
                  <Fab
                    type='submit'
                    variant='extended'
                    color='primary'
                    className='p-10 border-1 bg-black rounded-3xl w-200 text-white mt-8 ml-20'
                    style={{
                      position: 'fixed',
                      bottom: '20px',
                      zIndex: 1000,
                      ...(isMobile
                        ? { marginRight: 10, right: '0', width: 'calc(30% - 20px)' }
                        : { right: '20px' }), // Aplica la propiedad left solo si no es móvil
                    }}
                    disabled={!enrollData || enrollData.data?.isValid || submitionOnReview}
                    onClick={handleNext}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {activeStep === steps.length - 1 ? 'Sent Application' : 'Next'}
                      <ArrowRight />
                    </div>
                  </Fab>
                </Grid>
              )}
            </Grid>
          )}
        </div>
        {renderStepContent(activeStep)}
      </div>
    </Container>
  );
};

export default EnrollmentForm;
