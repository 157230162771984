import { Typography, Grid } from '@mui/material';
// import './styles.css';

const ConfirmationMenssage = () => {
  return (
    <Grid
      container
      style={{
        minHeight: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Grid
        item
        xs={12}
        md={10}
        lg={10}
      >
        {' '}
        <Typography
          variant='h6'
          align='center'
          gutterBottom
          style={{
            backgroundColor: '#f0f0f0',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            lineHeight: '1.6',
          }}
        >
          Great news – your application is in the review stage. We're thrilled about the prospect of
          having you on board and are eager to offer you a top-notch digital transaction experience.
          Stay tuned! We'll keep you updated on the progress of your merchant application and will
          notify you as soon as a decision is made. Thank you for choosing us, and we're excited
          about the journey ahead! Warm regards
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConfirmationMenssage;
