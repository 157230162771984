import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Card, CardContent, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FuseLoading from '@fuse/core/FuseLoading';
import { states, processorsURL, countryList, ipayNet } from '../../../constants/constants';
import moment from 'moment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useForm } from '../Custom/UseForm';
import { formatPhoneNumber } from 'react-phone-number-input';
const phoneregex = RegExp('[0-9]');

export default function FormPersonal({
  setValidate = () => {},
  save = () => {},
  data = {},
  resetForm = () => {},
  setCustomerId = () => {},
  _showMessage = () => {},
}) {
  const regexEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const [dateOfBirth, setDateOfBirth] = useState(
    moment().subtract(18, 'years').format('YYYY-MM-DD')
  );
  const [selectState, setSelectState] = useState('');

  const {
    firstName,
    lastName,
    email,
    type,
    ssn,
    address1,
    address2,
    city,
    postalCode,
    phone,
    correlationId,
    onInputChange,
    onResetForm,
    formState,
    setFormState,
  } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    type: 'personal',
    ssn: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    phone: '',
    correlationId: '',
  });

  const handleInputNumber = (e, max) => {
    if (e?.target?.value?.length === max + 1) {
      return;
    }
    onInputChange(e);
  };

  const customHeight = {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 52,
    },
  };

  const saveCustomer = async (relationId = '') => {
    const response = await fetch(`${processorsURL}/api/dwolla/create-customer-personal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        project: ipayNet.project,
        X_API_KEY: ipayNet.X_API_KEY,
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        type,
        ssn,
        address1,
        address2,
        city,
        postalCode,
        phone: phone.replace(/[^0-9]/g, ''),
        correlationId,
        state: selectState,
        dateOfBirth,
        relationId: relationId,
      }),
    });

    if (response.status != 200) {
      _showMessage(await response.text(), 'error');
      return;
    }

    const data = await response.json();
    setCustomerId(data.id);
    _showMessage('The customer has been created.', 'success', true);
  };

  const validateForm = () => {
    const validate =
      firstName.length > 2 &&
      lastName.length > 2 &&
      regexEmail.test(email) &&
      ssn.length == 9 &&
      address1.length > 2 &&
      city.length > 2 &&
      postalCode.length == 5 &&
      selectState?.length > 2
        ? false
        : true;
    setValidate(validate);

    return validate;
  };

  useEffect(() => {
    validateForm();
    save.current = saveCustomer;
  }, [firstName, lastName, email, ssn, address1, city, postalCode, selectState, phone]);

  useEffect(() => {
    const newState = { ...formState };
    newState.email = data?.comp_email || '';
    newState.address1 = data?.compaddress || '';
    newState.city = data?.compcity || '';
    newState.postalCode = data?.compzip || '';
    newState.phone = data?.compphone || '';

    setFormState(newState);

    save.current = saveCustomer;
    resetForm.current = onResetForm;

    setSelectState('Texas');
  }, []);

  return (
    <div className='container mx-auto'>
      <Box sx={{ my: 3, mx: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
          >
            <TextField
              value={email}
              onChange={onInputChange}
              name='email'
              sx={customHeight}
              fullWidth
              label='Email'
              required
              color={regexEmail.test(email) ? 'success' : 'error'}
              variant='outlined'
              type={'email'}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
          >
            <TextField
              value={firstName}
              sx={customHeight}
              name='firstName'
              fullWidth
              label='First Name'
              required
              color={firstName.length > 2 ? 'success' : 'error'}
              variant='outlined'
              onChange={onInputChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
          >
            <TextField
              value={lastName}
              sx={customHeight}
              name='lastName'
              color={lastName.length > 2 ? 'success' : 'error'}
              required
              fullWidth
              label='Last Name'
              variant='outlined'
              onChange={onInputChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 3, mx: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label='Date Of Birth *'
                value={dateOfBirth || moment().format('YYYY-MM-DD')}
                onChange={(e) => {
                  setDateOfBirth(moment(e).format('YYYY-MM-DD'));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          >
            <TextField
              value={ssn}
              sx={customHeight}
              name='ssn'
              type={'password'}
              color={ssn.length == 9 ? 'success' : 'error'}
              required
              fullWidth
              label='SSN'
              inputProps={{ maxLength: 9 }}
              variant='outlined'
              onChange={onInputChange}
              placeholder={'123456789'}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 3, mx: 2 }}>
        <Typography
          variant='h5'
          gutterBottom
          component='div'
        >
          Location Information
        </Typography>
      </Box>

      <Box sx={{ my: 3, mx: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
          >
            <TextField
              value={address1}
              sx={customHeight}
              name='address1'
              fullWidth
              label='Address 1 '
              variant='outlined'
              onChange={onInputChange}
              required
              color={address1.length > 2 ? 'success' : 'error'}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
          >
            <TextField
              value={address2}
              sx={customHeight}
              name='address2'
              fullWidth
              label='Address 2 (Optional) '
              variant='outlined'
              onChange={onInputChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
          >
            <TextField
              value={city}
              sx={customHeight}
              required
              color={city.length > 2 ? 'success' : 'error'}
              name='city'
              fullWidth
              label='City'
              variant='outlined'
              onChange={onInputChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
          >
            <Autocomplete
              freeSolo
              options={states}
              renderInput={(params) => (
                <TextField
                  required
                  color={selectState?.length > 4 ? 'success' : 'error'}
                  {...params}
                  label={'State'}
                />
              )}
              value={selectState}
              onChange={(event, newInputValue) => {
                setSelectState(newInputValue);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            lg={2}
          >
            <TextField
              value={postalCode}
              required
              color={postalCode.length == 5 ? 'success' : 'error'}
              sx={customHeight}
              name='postalCode'
              fullWidth
              label='Postal Code'
              variant='outlined'
              type={'number'}
              onChange={(e) => {
                handleInputNumber(e, 5);
              }}
              placeholder={'12345'}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 3, mx: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
          >
            <TextField
              value={phone}
              sx={customHeight}
              name='phone'
              fullWidth
              label='Phone'
              variant='outlined'
              required
              onChange={(e) => {
                onInputChange(e);
              }}
              placeholder={'0000000000'}
              onInput={(e) => {
                if (!phoneregex.test(e.target.value.slice(-1))) {
                  e.target.value = e.target.value.slice(0, -1);
                }

                const newphone = formatPhoneNumber('+1' + e.target.value);

                if (newphone.length > 1) {
                  e.target.value = newphone;
                } else {
                  e.target.value = e.target.value;
                }
              }}
              inputProps={{ maxLength: 14 }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
