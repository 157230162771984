import { useState, useEffect } from 'react';
import FormCustomer from 'components/Form/Dwolla/FormCustomer';
import { newServer } from 'constants/constants';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';

export default function CompanyForm({
  save,
  setSubmitionOnReview,
  setDataLoaded,
  dataLoaded,
}: any) {
  const [data] = useState(null);
  const [, setValidate] = useState(true);
  // const [validateOwner, setValidateOwner] = useState(true);
  const [, setCustomerId] = useState(null);
  // const [validatebeneficial, setValidateBeneficial] = useState(true);
  // const [isUpload, setIsUpload] = useState(false);
  const [, setBusinessType] = useState('Sole Proprietorship');
  const [isInvitation] = useState(false);
  // const [isOpenModal, setIsOpenModal] = useState(false);
  const [infoDwolla, setInfoDwolla] = useState(null);
  // const [hiddenCreateOwner, setHiddenCreateOwner] = useState(true);
  // const [percentageController, setPercentageController] = useState(0);
  // const [isChecked, setIsChecked] = useState(false);
  // const [validateTwo, setValidateTwo] = useState(true);
  const { user, logout } = useLogin();
  const { setStateDwolla, setStateUsio } = useEnrollForm();

  // const handleClose = () => {
  //   setIsOpenModal(false);
  // };

  useEffect(() => {
    const getInfoDwolla = async () => {
      if (user?.user_id) {
        try {
          const getDataDwolla = await fetch(`${newServer}requests/user/${user?.user_id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.token}`,
            },
          });
          setDataLoaded(true);

          const _jsonInfoDwolla = await getDataDwolla.json();

          if (_jsonInfoDwolla.message === 'Access denied') {
            logout();
            return;
          }

          setStateDwolla(_jsonInfoDwolla.status_dwolla_id);
          setStateUsio(_jsonInfoDwolla.status_usio_id);

          if (_jsonInfoDwolla.status_dwolla_id === 1) {
            setSubmitionOnReview(true);
          }

          // setPercentageController(_jsonInfoDwolla?.controller?.percentage);
          if (_jsonInfoDwolla.business_type === 'soleProprietorship') {
            setBusinessType('Sole Proprietorship');
          } else {
            setBusinessType(_jsonInfoDwolla.business_type);
          }
          setInfoDwolla(_jsonInfoDwolla?.info_dwolla);

          return _jsonInfoDwolla;
        } catch (error) {
          setStateDwolla(0);
          console.log('getInfoDwolla', error);
        }
      }
    };
    try {
      getInfoDwolla();
    } catch (error) {
      setDataLoaded(true);
    }
  }, [
    logout,
    setStateDwolla,
    setStateUsio,
    setSubmitionOnReview,
    user?.token,
    user?.user_id,
    setDataLoaded,
  ]);

  // useEffect(() => {
  //   if (isUpload) {
  //     handleClose();
  //   }
  //   if (isUpload == true && validatebeneficial == true) {
  //     setValidateOwner(false);
  //   } else {
  //     setValidateOwner(true);
  //   }
  // }, [isUpload, validatebeneficial]);

  // useEffect(() => {
  //   if (validate === false && isChecked === true) {
  //     setValidateTwo(false);
  //   } else {
  //     setValidateTwo(true);
  //   }
  // }, [validate, isChecked]);

  return (
    <div>
      {dataLoaded && (
        <FormCustomer
          isInvitation={isInvitation}
          data={data as any}
          infoDwolla={infoDwolla as any}
          setValidate={setValidate as any}
          save={save as any}
          setCustomerId={setCustomerId as any}
          _showMessage={() => {}}
          setBusinessType={setBusinessType as any}
          webhook={''}
        />
      )}
    </div>
  );
}
