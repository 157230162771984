import { Dialog } from "@mui/material";
import AuthForm from "components/AuthForm/AuthForm";

interface InformationDialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
}

export const LoginDialog = (props: InformationDialogProps) => {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <AuthForm isModal={true} />
    </Dialog>
  );
};
