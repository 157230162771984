import { Container, Grid, Typography } from '@mui/material';
import { Header } from 'components/Header/Header';
import './styles.css';

export const Policies = () => {
  return (
    <Grid>
      <Header />
      <Grid>
        <Typography
          variant='h3'
          textAlign={'center'}
        >
          Privacy policy
        </Typography>

        <Grid>
          <Typography
            variant={'body1'}
            textAlign={'center'}
          >
            // Todo : add text here
          </Typography>
        </Grid>
      </Grid>
    </Grid>//TODO External Microservice Dynamic content for the Policies , Configurable from WYSIWIG Editor
  );
};
