import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';

const MenssgePostDay = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid>
        <Typography
          variant='h4'
          align='center'
          gutterBottom
          className='bold'
        >
          {' '}
          While in Review, your account may take up to 2 days to be verified.
        </Typography>
      </Grid>
    </div>
  );
};

export default MenssgePostDay;
