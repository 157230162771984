import { FunctionComponent } from 'react';
import FormContainer from './FormContainer';
import './styles.css';
import { HomeConfig } from 'Config/home.config';

const FundraiserSection1: FunctionComponent = () => {
  return (
    <div className='sm:hidden md:hidden self-stretch flex flex-row py-[70px] px-[50px] items-center justify-center text-left text-sm text-darkslategray font-inter lg:pt-0 lg:pb-0 lg:box-border md:self-stretch md:w-auto md:p-0 md:h-auto sm:pl-5 sm:pr-5 sm:box-border max-screen'>
      <div className='w-[1600px] flex flex-row items-start justify-center lg:flex-1 md:flex-1 md:flex-col max-screen'>
        <div className='flex-1 flex flex-row items-start justify-center gap-[20px] lg:flex-row lg:gap-[0px] md:self-stretch md:w-auto md:flex-col md:gap-[60px] md:flex-[unset] max-screen'>
          <div className=' flex flex-col items-start justify-start gap-[13px] lg:flex-1 md:self-stretch md:w-auto md:items-center md:justify-start md:pl-[50px] md:pr-[50px] md:box-border sm:pl-5 sm:pr-5 sm:box-border max-screen'>
            <h3 className='m-0 self-stretch relative text-10xl font-light font-inherit md:text-center md:self-stretch md:w-auto sm:text-3xl sm:text-center'>
              {HomeConfig.section1.title}
            </h3>
            <p className='m-0  relative leading-[24px] font-light md:text-center  md:w-auto sm:text-center responsive-text'></p>
            <p className='m-0  relative leading-[24px] font-light md:text-center  md:w-auto sm:text-center responsive-text max-screen'>
              <span className='block'>
                {HomeConfig.section1.description}
                <br></br>
                <br></br>
              </span>
              <span className='block'>{HomeConfig.section1.description2}</span>
            </p>
            <div className='self-stretch flex flex-col pt-[70px] px-0 pb-0 items-start justify-start gap-[70px] text-center text-base sm:flex-col md:flex-col sm:gap-[40px] items-container'>
              {HomeConfig.section1.features.map((item, key) => (
                <FormContainer
                  key={key}
                  imageDimensions={
                    <img
                      src={item.logo}
                      alt='Support'
                      width={60}
                      height={60}
                    />
                  }
                  featureDescription={item.title}
                  imageDimensionsAlt={
                    <img
                      src={item.logo2}
                      alt='Gavel'
                      width={60}
                      height={60}
                    />
                  }
                  featureSubtitle={item.title2}
                />
              ))}
            </div>
          </div>

          <div className='flex-1 flex flex-col items-center justify-start gap-[13px] text-center text-slategray lg:flex-1 lg:self-stretch lg:h-auto lg:items-center lg:justify-start md:flex-[unset] md:pl-[0px] md:pr-150 md:box-border md:self-stretch sm:pl-10 sm:box- '>
            <div className='image-container'>
              <img
                className='relative w-[980px] h-[805px] object-cover  md:h-[805px] sm:w-[430px] md:w-[430px] sm:h-[605px] img-home-section'
                alt=''
                src={HomeConfig.section1.bannerImg}
              />
              <div className='overlay-text sm:w-[230px] sm:mt-[40px] sm:pr-[50px] sm:text-xs md:text-xs descrition-text-section'>
                {HomeConfig.section1.bannerDescription} <br />
                <br />
                <span className='text-14xl text-blue-600 sm:text-xs md:text-xs'>
                  {HomeConfig.section1.bannerAuthor}
                </span>
                <br />{' '}
                <span className='text-7xl text-blue-600 sm:text-xs md:text-xs'>
                  {HomeConfig.section1.bannerAuthorTitle}
                </span>
                <br />
                <span className='text-7xl text-blue-600'>
                  {HomeConfig.section1.bannerCompanyName}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserSection1;
