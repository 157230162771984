import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { UserAuth } from 'Modals/UserAuth';
import { newServer } from 'constants/constants';
import React, { useEffect, createContext, useContext, useState } from 'react';

interface EnrollContextType {
  saveEnrrollData: (data: any) => void;
  saveEnrrollDataUsio: (data: any) => void;
  setStateDwolla: (data: number) => void;
  setStateUsio: (data: number) => void;
  enrollData: any;
  enrollDataUsio: any;
  stateDwolla: number;
  stateUsio: number;
}

const EnrollContext = createContext<EnrollContextType>({
  enrollData: null,
  enrollDataUsio: null,
  stateDwolla: 1,
  setStateDwolla: (data: any) => {},
  saveEnrrollDataUsio: () => {},
  saveEnrrollData: (data: any) => {},
  setStateUsio: (data: any) => {},
  stateUsio: 0,
});

export const useEnrollForm = () => {
  return useContext(EnrollContext);
};

export const EnrollProvider: any = ({ children }: any) => {
  const [enrollData, setEnrollData] = useState({});
  const [enrollDataUsio, setEnrollDataUsio] = useState({});
  const [stateDwolla, setStateDwolla] = useState<number>(0);
  const [stateUsio, setStateUsio] = useState<number>(0);
  const { user } = useLogin();

  const saveEnrrollData = (data: any) => {
    setEnrollData((res: any) => {
      const updateData = { ...res, data };
      return updateData;
    });
  };

  const saveEnrrollDataUsio = (data: any) => {
    setEnrollDataUsio((res: any) => {
      const updateData = { ...res, data };
      return updateData;
    });
  };

  return (
    // @ts-ignore
    <EnrollContext.Provider
      value={{
        enrollData,
        enrollDataUsio,
        saveEnrrollData,
        saveEnrrollDataUsio,
        setStateDwolla,
        stateDwolla,
        setStateUsio,
        stateUsio,
      }}
    >
      {children}
    </EnrollContext.Provider>
  );
};
