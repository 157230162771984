import { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { formcustomercontroller, statesAbbreviations } from '../../../constants/constants';
import WarningIcon from '@mui/icons-material/Warning';
import InputAdornment from '@mui/material/InputAdornment';
// nameController, lastNameController,
export default function FormController({
  handleChange,
  customHeight,
  states,
  countryList,
  titleContoller,
  dateOfBirthController,
  setDateOfBirthController,
  ssnController,
  address1Controller,
  address2Controller,
  cityController,
  postalCodeController,
  numberPassportController,
  countryPassportController,
  selectStateController,
  setSelectStateController,
  selectCountryController,
  setSelectCountryController,
  handleInputNumber,
  infoDwolla,
}) {
  const [isPassport, setIsPassport] = useState(false);

  const handleCheck = ({ target }) => {
    setIsPassport(target.checked);
  };

  const handleAutocompleteChange = (event, newValue) => {
    const uppercaseValue = event.target.value.toUpperCase();
    if (statesAbbreviations[uppercaseValue]) {
      setSelectStateController(statesAbbreviations[uppercaseValue]);
    } else {
      setSelectStateController(newValue);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid
            container
            spacing={2}
          >
            {isPassport ? (
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
              >
                <TextField
                  value={numberPassportController}
                  color={numberPassportController.length > 2 ? 'success' : 'error'}
                  sx={customHeight}
                  error={numberPassportController?.length <= 2 && numberPassportController}
                  name='numberPassportController'
                  type={'number'}
                  fullWidth
                  label='Number Passport *'
                  variant='outlined'
                  onChange={handleChange}
                  placeholder={''}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>

        <Box sx={{ my: 3, mx: 2 }}>
          <Grid
            container
            spacing={2}
          >
            {isPassport && (
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
              >
                <TextField
                  value={countryPassportController}
                  sx={customHeight}
                  name='countryPassportController'
                  fullWidth
                  label='Issuing Country Passport'
                  variant='outlined'
                  required
                  color={countryPassportController.length > 2 ? 'success' : 'error'}
                  error={countryPassportController?.length <= 2 && countryPassportController}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <TextField
                value={titleContoller}
                required
                color={titleContoller.length > 2 ? 'success' : 'error'}
                error={titleContoller?.length <= 2 && titleContoller}
                onChange={handleChange}
                name='titleContoller'
                sx={customHeight}
                fullWidth
                label='Title'
                variant='outlined'
                placeholder='Business Controller. IE - Chief Financial Officer'
                helperText={
                  titleContoller.length > 2 ? '' : formcustomercontroller.titleContoller.message
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={isPassport ? 2 : 2}
              lg={isPassport ? 2 : 2}
            >
              <TextField
                value={address1Controller}
                sx={customHeight}
                required
                name='address1Controller'
                fullWidth
                label='Owner Address 1'
                helperText={
                  address1Controller.length > 2 ? '' : formcustomercontroller.address.message
                }
                variant='outlined'
                color={address1Controller.length > 2 ? 'success' : 'error'}
                error={address1Controller?.length <= 2 && address1Controller}
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <TextField
                value={address2Controller}
                sx={customHeight}
                name='address2Controller'
                fullWidth
                label='Address 2 (Optional)'
                variant='outlined'
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <TextField
                value={cityController}
                sx={customHeight}
                name='cityController'
                required
                helperText={cityController.length > 2 ? '' : formcustomercontroller.city.message}
                color={cityController.length > 2 ? 'success' : 'error'}
                error={cityController?.length <= 2 && cityController}
                fullWidth
                label='City'
                variant='outlined'
                onChange={handleChange}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <Autocomplete
                options={states}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    color={selectStateController?.length > 2 ? 'success' : 'error'}
                    label={'State'}
                    onChange={handleAutocompleteChange}
                  />
                )}
                value={selectStateController}
                onChange={(event, newInputValue) => {
                  setSelectStateController(newInputValue);
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={2}
            >
              <TextField
                value={postalCodeController}
                color={postalCodeController?.length == 5 ? 'success' : 'error'}
                error={postalCodeController?.length <= 4 && postalCodeController}
                InputProps={{
                  endAdornment:
                    postalCodeController?.length <= 4 && postalCodeController ? (
                      <InputAdornment position='end'>
                        <WarningIcon style={{ color: 'red', fontSize: 10 }} />
                      </InputAdornment>
                    ) : null,
                }}
                sx={customHeight}
                name='postalCodeController'
                fullWidth
                label='Postal Code *'
                variant='outlined'
                type={'number'}
                onChange={(e) => {
                  handleInputNumber(e, 5);
                }}
                placeholder={'12345'}
                inputProps={{
                  inputMode: 'numeric',
                }}
                helperText={
                  postalCodeController?.length == 5 ? '' : formcustomercontroller.zip.message
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
