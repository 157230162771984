/* eslint-disable react-hooks/rules-of-hooks */
// dataValidationTable.tsx
import { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Button,
  Grid,
  Paper,
  Container,
  TextField,
  FormControlLabel,
  Theme,
  Switch,
} from '@mui/material';
import './styles.css'; // Import the CSS file
import { useDialog } from 'Contexts/Modals/ModalProvider';
import { ipayNet, newServer } from 'constants/constants';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import { makeStyles } from '@material-ui/core/styles';
interface data {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  attachmentUrl: string; // Add the appropriate type for attachment URL
  attachmentUrl2: string; // Add the appropriate type for attachment URL
  ipAddress: string;
  type: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  // selectedBusinessType: string;
  businessName: string;
  userIdImgs: string[];
  doingBusinessAs: string;
  businessType: string;
  ein: string;
  ssn: string;
  website: string;
  phone: string;
  correlationId: string;
  businessClassification: string;
  state: string;
  dateOfBirth: string;
  industry: string;
  controller: {
    firstName: string;
    lastName: string;
    title: string;
    dateOfBirth: string;
    ssn: string;
    ownershipPercent: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      stateProvinceRegion: string;
      postalCode: string;
      country: string;
    };
  };
}

// Hook de estilos personalizados

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}));

interface dataValidationTableProps {
  data: data;
  goBack: () => void;
}

const EnrollItem = ({ data, goBack }: dataValidationTableProps) => {
  const { openDialog } = useDialog();
  const [image1, setImage1] = useState<string>();
  const [image2, setImage2] = useState<string>();
  const [details, setDetails] = useState<string>();
  const { user } = useLogin();
  const classes = useStyles();

  useEffect(() => {
    if (data.userIdImgs) {
      cargarImagenes();
    }
  }, []);

  const cargarImagenes = async () => {
    try {
      const res = await fetch(`${newServer}aws/get-identification-request/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
          Authorization: `Bearer ${user?.token}`,
        },
      });

      const images: { imageUrls: string[] } = await res.json();

      for (let image of images.imageUrls) {
        if (image.includes(data.userIdImgs[0])) {
          setImage1(image);
        }
        if (image.includes(data.userIdImgs[1])) {
          setImage2(image);
        }
      }
    } catch (error) {
      console.log('cargarImagenes', error);
    }
  };

  /**
   * Handle request
   * @param status
   */
  const handleRequest = async (status: string) => {
    let acceptedStatus = 3;
    if (status === 'accept') {
      acceptedStatus = 2;
    }
    try {
      const res = await fetch(`${newServer}requests/status-internal/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': ipayNet.apiToken,
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({
          status_dwolla: acceptedStatus,
          status_usio: acceptedStatus,
        }),
      });
      const responseText = await res.text();
      if ((await responseText) === 'OK') {
        goBack();
      } else {
        openDialog('information', null, {
          title: 'Error',
          description: 'The application could not be modified: ' + responseText,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Handle Aprove
   */
  const handleApprove = () => {
    openDialog('information', null, {
      title: 'Approval Request',
      description: 'Are you sure you want to approve this request',
      cancelText: 'Cancel',
      onConfirm: () => handleRequest('accept'),
    });
  };

  /**
   * Handle deny
   */
  const handleDeny = () => {
    openDialog('information', null, {
      title: 'Deny Request',
      input: details,
      setInput: (value: string) => setDetails(value as any),
      inputPlaceholder: 'Please provide a reason for the denial',
      description: 'Are you sure you want to deny this request',
      cancelText: 'Cancel',
      onConfirm: () => handleRequest('deny'),
      isMultiline: true,
    });
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Card className='back-button'>
        <div>
          <Button onClick={goBack}>Back</Button>
        </div>
      </Card>
      {data && (
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Paper className='card'>
            <Grid style={{ display: 'flex' }}>
              <Grid item>
                <Typography
                  variant='h6'
                  gutterBottom
                >
                  Request Details
                </Typography>
                <section className='personal-info'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Name: {data.firstName} {data.lastName}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Email: {data.email}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Phone: {data.phone}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Date of Birth: {data.dateOfBirth}
                  </Typography>
                </section>
                <section className='business-info'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Business Name: {data.businessName}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Business Type: {data.businessType}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    EIN: ****
                    {data.ein.substring(data.ein.length - 4, data.ein.length)}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                  >
                    Website:{' '}
                    <a
                      href={data.website}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {data.website}
                    </a>
                  </Typography>
                </section>

                {data.controller && (
                  <section className='controller-info'>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      gutterBottom
                    >
                      Controller: {data.controller.firstName} {data.controller.lastName}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      gutterBottom
                    >
                      Controller Title: {data.controller.title}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      gutterBottom
                    >
                      Controller Date of Birth: {data.controller.dateOfBirth}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      gutterBottom
                    >
                      Controller SSN: ****
                      {data.controller.ssn.substring(
                        data.controller.ssn.length - 4,
                        data.controller.ssn.length
                      )}
                    </Typography>
                  </section>
                )}
              </Grid>
              <Grid
                item
                sm={6}
                className='doc-image'
              >
                <div className='doc-image-container'>
                  {image1 && (
                    <img
                      src={image1}
                      alt='Attachment'
                      className='attachment'
                    />
                  )}
                  {image2 && (
                    <img
                      src={image2}
                      alt='Attachment'
                      className='attachment'
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            <div className='button-container'>
              <Button
                variant='contained'
                className='approveButton'
                onClick={handleApprove}
                style={{ backgroundColor: 'green', marginRight: '8px' }}
              >
                Approve
              </Button>
              <Button
                variant='contained'
                className='denyButton'
                onClick={handleDeny}
                style={{ backgroundColor: 'red' }}
              >
                Deny
              </Button>
            </div>
          </Paper>

          {/* <Container
            className={classes.container}
            maxWidth='xs'
          >
            <Typography
              variant='h6'
              className={classes.title}
            >
              Edit Customer
            </Typography>
            <TextField
              className={classes.textField}
              fullWidth
              required
              id='full-name'
              label='Full Name'
              defaultValue='Miron Vitold'
              variant='outlined'
            />
            <TextField
              className={classes.textField}
              fullWidth
              required
              id='email'
              label='Email Address'
              defaultValue='miron.vitold@devias.io'
              variant='outlined'
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={true}
                  name='availableToHire'
                />
              }
              label='Available to hire'
            />
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              fullWidth
            >
              Update
            </Button>
            <Button
              className={classes.button}
              fullWidth
            >
              Cancel
            </Button>
          </Container> */}
        </Grid>
      )}
    </Grid>
  );
};

export default EnrollItem;
