import { ipayNet, newServer } from 'constants/constants';
import { refactorImgUrl } from './refactor-img-url';

export const uploadImage = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async function () {
      const base64String = (reader as any)?.result?.replace('data:', '').replace(/^.+,/, '');

      const bodyObject = {
        image: `data:${file.type};base64,${base64String}`,
      };

      try {
        const response = await fetch(`${newServer}aws/post-identification-request/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': ipayNet.apiToken,
          },
          body: JSON.stringify(bodyObject),
        });

        const data = await response.json();
        if (!data?.key) {
          reject(new Error('Failed to upload image'));
          return;
        }

        resolve(refactorImgUrl(data.key));
      } catch (error) {
        reject(new Error(`Failed to upload image ${JSON.stringify(error)}`));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read the file'));
    };

    reader.readAsDataURL(file);
  });
};
