import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Icon,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface ResetPasswordDialogProps {
  data?: any;
  onClose: (value: boolean) => void;
  open: boolean;
}

const ResetPasswordDialog = (props: ResetPasswordDialogProps) => {
  const { data, onClose, open } = props;

  const [input, setInput] = useState<string>();

  const handleClose = () => {
    onClose(false);
  };

  const handleConfirm = () => {
    if (data?.onConfirm) {
      data?.onConfirm(input);
    }
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: 30, padding: '20px', minWidth: '300px' }, // Estilos para el papel del dialog
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Asegúrate de que todo esté centrado
          flexDirection: window.innerWidth <= 600 ? 'column' : 'row', // Cambia a columna en móviles
          padding: window.innerWidth <= 600 ? '10px' : '20px', // Padding más coherente
          borderBottom: '1px solid #e0e0e0', // Línea sutil para separar del contenido
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Asegura que el contenido del div esté centrado
            marginBottom: window.innerWidth <= 600 ? '10px' : '0', // Margen inferior solo en móviles
          }}
        >
          {data?.icon && (
            <Icon
              style={{
                display: 'flex',
                marginRight: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {data?.icon}
            </Icon>
          )}
          {data?.title && (
            <span
              style={{
                fontSize: window.innerWidth <= 600 ? '1em' : '1.2em', // Tamaño de fuente adaptable
                fontWeight: 'bold',
                textAlign: 'center', // Asegura que el texto esté centrado
                color: '#333', // Color oscuro para el texto para mejorar la legibilidad
              }}
            >
              {data?.title}
            </span>
          )}
        </div>
      </DialogTitle>

      <DialogContent style={{ width: '100%', marginTop: '10px' }}>
        {data?.description && (
          <DialogContentText style={{ textAlign: 'center', marginBottom: '20px' }}>
            {data?.description}
          </DialogContentText>
        )}
        {data?.setInput && (
          <TextField
            label={data.inputPlaceholder}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={{ marginBottom: '10px' }}
            InputProps={{
              style: {
                borderRadius: '15px', // Esto aplica el borderRadius al contenedor del input
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={handleClose}
          style={{
            marginRight: '8px',
            backgroundColor: '#f0f0f0',
            color: 'black',
            width: '500px',
            height: '50px',
            borderRadius: '15px',
            fontSize: '1rem',
          }}
        >
          {data?.cancelText || 'Cancel'}
        </Button>
        {data?.onConfirm && (
          <Button
            onClick={handleConfirm}
            className='confirmButton'
            style={{
              backgroundColor: '#ff0099',
              color: 'white',
              width: '500px',
              height: '50px',
              borderRadius: '15px',
              fontSize: '1rem',
            }}
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
