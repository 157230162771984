import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import React, { createContext, useContext, useEffect, useState } from 'react';

const LoginContext = createContext<any>({
  user: null,
  setUser: (user: any) => {},
  logout: () => {},
});

export const useLogin = () => {
  return useContext(LoginContext);
};

export const LoginProvider: any = ({ children }: any) => {
  const [user, saveUser] = useState<any>();

  const setUser = (user: any) => {
    localStorage.setItem('token', user?.token);
    localStorage.setItem('user_id', user?.user_id);
    localStorage.setItem('uuid', user?.uuid);
    if (!user.user_id) {
      user.user_id = 1;
    }

    saveUser(user);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('uuid');
    saveUser(undefined);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const uuid = localStorage.getItem('uuid');
    saveUser((user: any) => {
      return { ...user, token, user_id, uuid };
    });
  }, []);

  return (
    // @ts-ignore
    <LoginContext.Provider value={{ user, setUser, logout }}>{children}</LoginContext.Provider>
  );
};
