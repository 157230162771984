import EnrollmentSteps from 'components/EnrollmentSteps/EnrollmentSteps';
import { Header } from 'components/Header/Header';

export default function DwollaApp({ store }: any) {
  return (
    <div>
      <Header />
      <EnrollmentSteps />
    </div>
  );
}
