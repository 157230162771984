import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
  InputAdornment,
} from '@mui/material';
import { AddCircleOutline, CheckCircle } from '@mui/icons-material';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import './styles.css';
import { ipayNet, newServer } from 'constants/constants';

const FundingStep = () => {
  const { user } = useLogin();
  const [subDomain, setSubDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [textIndex, setTextIndex] = React.useState(0);
  const loadingTexts = [
    `Preparing Your Personalized Subdomain Experience...`,
    `Great news! We're now setting up your exclusive subdomain web app. This process might take a few moments, so we appreciate your patience....`,
    `Your tailored web experience is just around the corner!...`,
  ]; //TODO Convert to read from Dynamic Source with "Loading Texts" Messages configured. Considerations for Platform variability.

  const getInfoDwolla = async () => {
    if (user?.user_id) {
      try {
        const getDataDwolla = await fetch(`${newServer}requests/user/${user?.user_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });
        const _jsonInfoDwolla = await getDataDwolla.json();
        if (_jsonInfoDwolla.finished) {
          setFinished(_jsonInfoDwolla.finished);
          if (_jsonInfoDwolla.subdomain.subdomain) {
            setSubDomain(_jsonInfoDwolla.subdomain.subdomain);
          }
        }
        return _jsonInfoDwolla;
      } catch (error) {
        console.log('getInfoDwolla', error);
      }
    }
  };

  const saveData = async () => {
    setLoading(true);
    const request = await getInfoDwolla();

    saveSubdomain(request.idSubmerchant, request.id);
  };

  const saveSubdomain = async (submerchant_id: any, id: any) => {
    try {
      const response = await fetch(`${newServer}subdomain/create-subdomain/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({ subdomain: subDomain, status: true, submerchant_id }),
      });

      const data = await response.text();
      setLoading(false);

      if (data === 'OK') {
        setFinished(true);
        const request = await getInfoDwolla();

        if (!selectedFile) return;

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);

        reader.onload = async () => {
          try {
            await fetch(`${newServer}aws/put-logo/`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': ipayNet.apiToken,
              },
              body: JSON.stringify({
                imageKeys: 'nothing',
                image: reader.result,
                idSubmerchant: request.idSubmerchant,
              }),
            });
          } catch (error) {
            setLoading(false);
            console.log('error:', error);
          }
        };
      }
    } catch (error) {
      setLoading(false);
      console.log('error:', error);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file as any);
    }
  };

  useEffect(() => {
    getInfoDwolla();
  }, []);

  useEffect(() => {
    if (finished) {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);

            //falta obtener el subdominio
            const baseUrl = process.env.REACT_APP_PLATFORM_BASE_URL || 'noahsarkdonate.com';
            window.location.href = `https://${subDomain}.${baseUrl}/login`;
            return 100;
          }
          return Math.min(oldProgress + 1, 100);
        });
      }, 100); // Ajusta el tiempo del intervalo segun sea necesario

      const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 3000); // Cambia el texto cada 3 segundos

      return () => {
        clearInterval(interval);
        clearInterval(intervalId);
      };
    }
  }, [finished]);

  return (
    <Grid className='card-container'>
      {finished ? (
        <div style={{ textAlign: 'center' }}>
          <div
            className='loader'
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50vh',
            }}
          >
            <div className='ring'></div>
            <div className='ring'></div>
            <div className='ring'></div>
            <div
              className='loading-text'
              style={{
                position: 'absolute',
                top: '50%', // Centra verticalmente
                left: '50%', // Centra horizontalmente
                transform: 'translate(-50%, -50%)', // Ajuste fino para centrar exactamente
                fontSize: '1.5rem', // Tamaño del texto
                color: 'black', // Color del texto
              }}
            >
              {progress}%
            </div>
          </div>
          <div className='h4'>{loadingTexts[textIndex]}</div>
        </div>
      ) : (
        <>
          <Grid
            container
            className='main-container'
            style={{ padding: '20px', backgroundColor: '#f4f4f4', borderRadius: '8px' }}
          >
            <Grid
              item
              xs={12}
            >
              <Typography
                variant='h6'
                align='center'
                gutterBottom
                style={{ margin: '20px 0', color: '#333', fontWeight: 'bold' }}
              >
                Complete your Payment Portal setup, upload a Company Logo, and choose your subdomain
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '10px' }}
            >
              <div
                style={{
                  border: '1px dashed #ccc',
                  padding: '20px',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt='Uploaded logo'
                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }}
                  />
                ) : (
                  <AddCircleOutline
                    fontSize='large'
                    color='action'
                  />
                )}
                <Typography
                  variant='subtitle1'
                  color='textSecondary'
                >
                  Upload logo for your page
                </Typography>
                <label htmlFor='file-input'>
                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png'
                    id='file-input'
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    component='span'
                    style={{ marginTop: '10px' }}
                  >
                    Choose File
                  </Button>
                </label>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '10px' }}
            >
              <div
                style={{
                  border: '1px solid #ccc',
                  padding: '20px',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant='subtitle1'
                  align='center'
                  color='textSecondary'
                >
                  Add the name of your subdomain for your account portal
                </Typography>
                <TextField
                  fullWidth
                  label='Subdomain name'
                  variant='outlined'
                  type='text'
                  margin='normal'
                  value={subDomain}
                  onChange={(e) => setSubDomain(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>.noahsarkdonate.com</span>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginTop: '10px' }}
                />
              </div>
            </Grid>
            <Button
              variant='contained'
              color='secondary'
              fullWidth
              onClick={saveData}
              style={{
                marginTop: '20px',
                padding: '10px 0',
                fontSize: '16px',
              }}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{ color: 'white' }}
                />
              ) : (
                'Continue'
              )}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FundingStep;
