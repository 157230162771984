import { HomeConfig } from 'Config/home.config';
import { FunctionComponent } from 'react';

const CardContainer: FunctionComponent = () => {
  return (
    <div className='self-stretch flex flex-row py-[100px] px-[50px] items-center justify-center text-center text-10xl text-darkslategray font-inter md:w-full md:pt-[50px] md:pb-[50px] md:box-border sm:flex-1 sm:pt-0 sm:pb-0 sm:box-border'>
      <div className='w-[1600px] flex flex-col items-center justify-start lg:flex-1 md:w-full md:flex-col sm:h-auto'>
        <div className='self-stretch flex flex-row items-start justify-center md:flex-col sm:h-auto'>
          <div className='flex-1 flex flex-row items-start justify-center lg:flex-1 md:flex-col md:flex-[unset] md:self-stretch sm:h-auto'>
            {/* <div className='flex-1 flex flex-col items-center justify-start gap-[20px] md:flex-col md:flex-[unset] md:self-stretch sm:h-auto'>
              <div className='relative font-light md:text-lg md:self-stretch md:w-auto sm:flex-1'>{HomeConfig.section2.title2}</div>
              <div className='w-[1248px] h-[100px] flex flex-col items-start justify-start relative lg:flex-col lg:items-center lg:justify-start md:w-full sm:h-auto'>
                <div className='w-[1278px] flex flex-row pt-0 pb-1.5 pr-[123.02001953125px] pl-[152.97999572753906px] box-border items-start justify-center gap-[63px] z-[0] lg:gap-[40px] lg:pl-10 lg:pr-10 lg:pb-0 lg:box-border md:self-stretch md:w-auto md:gap-[10px] md:pl-[50px] md:pr-[50px] md:box-border sm:flex-col sm:items-center sm:justify-center'>
                  <img
                    className='relative w-[150px] h-[100px] object-cover max-w-[213px]'
                    alt=''
                    src='/logo.png'
                  />
                  <img
                    className='relative w-[150px] h-[100px] object-cover max-w-[213px]'
                    alt=''
                    src='/logo.png'
                  />
                  <img
                    className='relative w-[150px] h-[100px] object-cover max-w-[213px]'
                    alt=''
                    src='/logo.png'
                  />
                  <img
                    className='relative w-[150px] h-[100px] object-cover max-w-[213px]'
                    alt=''
                    src='/logo.png'
                  />
                  <img
                    className='relative w-[150px] h-[100px] object-cover max-w-[213px]'
                    alt=''
                    src='/logo.png'
                  />
                </div>
                <div className='my-0 mx-[!important] absolute top-[25px] left-[calc(50%_-_624px)] flex flex-row items-start justify-start gap-[1166px] z-[1]'>
                  <button className='cursor-pointer [border:none] pt-0 px-0 pb-[0.15918231010437012px] bg-[transparent] flex flex-row box-border items-start justify-center max-w-[21px]'>
                    <div className='w-[21px] h-16 overflow-hidden shrink-0 flex flex-row py-0 pr-0 pl-[0.05221095308661461px] box-border items-start justify-start'>
                      <img
                        className='relative w-[21.04px] h-[64.16px]'
                        alt=''
                        src='/vector1.svg'
                      />
                    </div>
                  </button>
                  <button className='cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-start justify-center max-w-[21px]'>
                    <div className='w-[21px] h-16 overflow-hidden shrink-0 flex flex-row pt-[0.15917591750621796px] pb-0 pr-[0.0022113309241831303px] pl-[0.0032665743492543697px] box-border items-start justify-start'>
                      <img
                        className='relative w-[20.99px] h-[63.84px]'
                        alt=''
                        src='/vector2.svg'
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
