import { FunctionComponent } from 'react';
import logo from '../constanst';
import { HomeConfig } from 'Config/home.config';

const Footer: FunctionComponent = () => {
  return (
    <div className='self-stretch bg-gray-100 flex flex-row py-16 px-80 items-start justify-center text-left text-xs text-dimgray-200 font-inter lg:py-10 lg:px-[50px] lg:box-border sm:items-center sm:justify-start'>
      <div className='flex-1 flex flex-col items-start justify-start gap-[48px] max-w-[1280px] lg:w-full lg:gap-[20px] sm:gap-[20px] sm:items-center sm:justify-start'>
        <div className='self-stretch flex flex-row items-start justify-start gap-[40px] lg:self-stretch lg:w-auto md:flex-col md:items-center md:justify-start sm:items-center sm:justify-start'>
          <div className='flex-1 flex flex-row items-center justify-start gap-[28px] lg:flex-1 lg:gap-[15px] md:items-center md:justify-start md:flex-[unset] md:self-stretch sm:flex-col sm:gap-[10px]'>
            <img
              className='relative w-[100px] h-[100px] overflow-hidden shrink-0'
              alt=''
              src={HomeConfig.header.logo}
            />
            <div className='w-full flex flex-row pt-0 pb-px pr-10 pl-0 box-border items-start justify-start lg:flex-1 sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-start sm:pr-0 sm:box-border sm:text-center'>
              <div className='relative leading-[21px] font-light'>
                <p className='m-0'>{HomeConfig.footer.description}</p>
                <p className='m-0'>
                  Our mission is to help caretaking, rescuing, reuniting, and rehoming pets in need.
                </p>
              </div>
            </div>
          </div>
          <div className='flex-1 flex flex-row items-center justify-start gap-[40px] lg:gap-[15px] md:flex-[unset] md:self-stretch sm:flex-col sm:items-start sm:justify-start'>
            <b className='flex-1 relative text-smi leading-[21px] sm:flex-[unset] sm:self-stretch'>
              Here to help:
            </b>
            <div className='flex-1 flex flex-row items-start justify-start sm:flex-[unset] sm:self-stretch'>
              <div className='relative leading-[21px] font-light'>
                {HomeConfig.footer.time.map((item) => (
                  <div>
                    <p className='m-0'>{item.title}</p>
                    <p className='m-0'>{item.schedule}</p>{' '}
                  </div>
                ))}
              </div>
            </div>
            <div className='flex-1 flex flex-row items-start justify-start sm:flex-[unset] sm:self-stretch'>
              <div className='relative leading-[21px] font-light'>
                <p className='m-0'>{HomeConfig.footer.phone}</p>
                <p className='m-0'>{HomeConfig.footer.email}</p>
              </div>
            </div>
            {/* <div className="flex flex-row items-start justify-start gap-[10px]">
              <div className="flex flex-row items-start justify-center opacity-[0.5] max-w-[17.5px]">
                <div className="w-[17.5px] h-[17.5px] overflow-hidden shrink-0 flex flex-row items-start justify-start">
                  <img
                    className="flex-1 relative max-w-full overflow-hidden h-[17.55px]"
                    alt=""
                    src="/white-2.svg"
                  />
                </div>
              </div>
              <div className="w-[17.5px] flex flex-row items-start justify-center opacity-[0.5] max-w-[17.5px]">
                <img
                  className="flex-1 relative max-w-full overflow-hidden h-[17.5px]"
                  alt=""
                  src="/logolinkedinsvg.svg"
                />
              </div>
              <div className="flex flex-row items-start justify-center opacity-[0.5] max-w-[17.5px]">
                <div className="h-[17.5px] overflow-hidden flex flex-row pt-[4.046875px] pb-[3.9375px] pr-[2.953125px] pl-[2.8984375px] box-border items-start justify-start">
                  <img
                    className="relative w-[11.65px] h-[9.52px]"
                    alt=""
                    src="/vector6.svg"
                  />
                </div>
              </div>
              <div className="w-[17.5px] flex flex-row items-start justify-center opacity-[0.5] max-w-[17.5px]">
                <img
                  className="flex-1 relative max-w-full overflow-hidden h-[17.5px]"
                  alt=""
                  src="/logo-instagramsvg.svg"
                />
              </div>
              <div className="w-[17.5px] flex flex-row items-start justify-center opacity-[0.5] max-w-[17.5px]">
                <img
                  className="flex-1 relative max-w-full overflow-hidden h-[17.5px]"
                  alt=""
                  src="/logoyoutubesvg.svg"
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className='relative box-border w-[1280px] h-px border-t-[1px] border-solid border-gainsboro' />
        <div className='self-stretch flex flex-row items-start justify-center gap-[40px] text-center lg:self-stretch lg:w-auto sm:flex-col sm:gap-[10px]'>
          <div className='flex flex-row items-center justify-center'>
            <div className='relative font-light'>Terms of Use Policy</div>
          </div>
          <div className='flex flex-row items-center justify-center'>
            <div className='relative font-light'>Privacy Policy</div>
          </div>
          <div className='flex flex-row items-start justify-center text-smi'>
            <div className='relative font-light'>Hope you're having a great time!</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
