import { Typography, Button, Box, Card, CardContent, CircularProgress } from '@mui/material';
import { AddCircle } from '@material-ui/icons';
import { useCallback, useState, useEffect } from 'react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { usePlaidLink } from 'react-plaid-link';
import { WebhookURL, newServer } from 'constants/constants';
import { useLogin } from 'Contexts/AuthProvider/AuthProvider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ArrowForwardIos, Circle, Wallet } from '@mui/icons-material';
import { green } from '@mui/material/colors';

const PlaidStep = ({ setPublicToken = () => {}, setActiveStep }) => {
  const [token, setToken] = useState(null);
  const [accesToken, setAccessToken] = useState(null);
  const [listAccesToken, setListAccesToken] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [clientId, setClientId] = useState(null); //
  const [accountId, setAccountId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [nameButton, setNameButton] = useState('Add funding source');
  const [info, setInfo] = useState(null);
  const [statusPending, setStatusPending] = useState(false); //
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false); //
  const { user } = useLogin();

  // CREA EL LINK TOKEN
  const createLinkToken = async (accessToken = '', clientId = '') => {
    const clientIdNew = new Date().getTime().toString();
    setClientId(clientIdNew);

    console.log('clientId=>', clientIdNew);
    try {
      const response = await fetch(`${newServer}ipaynet/get-token-plaid`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
        body: JSON.stringify({ accessToken, clientId }),
      });

      const { link_token } = await response.json();
      setToken(link_token);
    } catch (e) {}
  };

  // OBTIENE EL ACCESS TOKEN
  const getAccesToken = async (publicToken) => {
    if (publicToken && accesToken == null) {
      try {
        const AccesToken = await fetch(`${newServer}ipaynet/get-access-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({ publicToken: publicToken }),
        });

        if (AccesToken.status !== 200) {
          return;
        }
        const _jsonAccesToken = await AccesToken.json();
        setAccessToken(_jsonAccesToken.access_token);
        console.log('_jsonAccesToken', _jsonAccesToken.access_token);

        if (_jsonAccesToken.access_token) {
          await getListAccount(_jsonAccesToken.access_token);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //OBTIENE LA LISTA DE CUENTAS
  const getListAccount = async (access_token) => {
    if (access_token) {
      try {
        const responseGetAccount = await fetch(`${newServer}ipaynet/get-list-accounts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({ accessToken: access_token }),
        });
        const _jsonListAccount = await responseGetAccount.json();
        setListAccesToken(_jsonListAccount.accounts);
        console.log('get-accounts=>', _jsonListAccount);
        if (_jsonListAccount) {
          setLoading(false);
          setIsOpen(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  //OBTIENE EL PROCESSOR TOKEN
  const getAccountId = async (accountId) => {
    setLoading(true);
    const customerID = await getIdDwolla();
    if (accountId) {
      try {
        if (
          verificationStatus == 'pending_automatic_verification' ||
          verificationStatus == 'pending_manual_verification'
        ) {
          creteFundingSource({ processor_token: 'Pending' }, customerID, WebhookURL, accountId);
          const status = await getInfoDwolla();
          console.log('status=>', status.status_dwolla_id);
          setActiveStep(10);
          setLoading(false);
          return;
        }

        if (accesToken) {
          const GetProcessorToken = await fetch(`${newServer}ipaynet/get-processor-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify({
              accessToken: accesToken,
              accountId: accountId.account_id,
              processor: 'dwolla',
            }),
          });

          const _jsonProcessorToken = await GetProcessorToken.json();
          console.log('get-processor-token=>', _jsonProcessorToken);

          if (_jsonProcessorToken.processor_token) {
            const responseCreatePlaidToken = await creteFundingSource(
              _jsonProcessorToken,
              customerID,
              WebhookURL,
              accountId
            );
            if (responseCreatePlaidToken.status !== 200) {
              setLoading(false);
              return;
            }
            const _jsonPlaidToken = await responseCreatePlaidToken.json();
            console.log('create-funding-source=>', _jsonPlaidToken);
            setActiveStep(16);
            setDisabled(false);
            setLoading(false);
          }
        }
      } catch (error) {
        setIsOpen(false);
        setLoading(false);
        setDisabled(false);
        console.log(error);
      }
    }
  };

  // OBTIENE LA INFORMACION
  const getInfo = async () => {
    const response = await fetch(`${newServer}ipaynet/get-info-plaid/${user?.user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}`,
      },
    });
    const data = await response.json();
    const newData = Object.keys(data).length === 0 ? null : data;
    setInfo(newData);
    return newData;
  };

  // FUNCION QUE CREA FUNDING SOURCE
  const creteFundingSource = async (_jsonProcessorToken, customerID, webhook = null, accountId) => {
    const info = await getInfo();
    console.log('info=>', info);

    const objectBody = {
      customerId: info ? info?.customerId : customerID,
      plaidToken: _jsonProcessorToken.processor_token,
      name: info ? info?.name : accountId.name,
      accountId: info ? info?.accountId : accountId.account_id,
      accessToken: info ? info?.accessToken : accesToken,
      microDeposit: false,
      webhook: info ? info?.webhook : webhook,
      clientId,
      verificationStatus,
    };

    console.log('objectBody=>', objectBody);
    console.log('verificationStatus=>', verificationStatus);

    if (
      verificationStatus == 'pending_automatic_verification' ||
      verificationStatus == 'pending_manual_verification'
    ) {
      objectBody.microDeposit = true;
    }
    setDisabled(false);
    return await fetch(`${newServer}ipaynet/create-funding-source/${user?.user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(objectBody),
    });
  };

  // OBTIENE EL ID DE DWOLLA
  const getIdDwolla = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${newServer}ipaynet/get-id-dwolla/${user?.uuid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });

        const data = await response.text();
        console.log('idDwolla=>', data);
        if (data.message) {
          return;
        }
        // setCustomerId(data);
        // getListFundingSource();
        resolve(data);
      } catch (error) {
        setLoading(false);
        reject(error);
      }
    });
  };

  const getInfoDwolla = async () => {
    if (user?.user_id) {
      try {
        const getDataDwolla = await fetch(`${newServer}requests/user/${user?.user_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.token}`,
          },
        });
        const _jsonInfoDwolla = await getDataDwolla.json();
        return _jsonInfoDwolla;
      } catch (error) {
        console.log('getInfoDwolla', error);
      }
    }
  };

  // get a link_token from your API when component mounts
  useEffect(() => {
    async function fetchAndSetInfo() {
      const info = await getInfo();
      const status = await getInfoDwolla();
      console.log('status=>', status);
      setNameButton(info ? 'Verify funding source' : 'Add funding source');
      createLinkToken(info?.accessToken, info?.clientId);
    }

    fetchAndSetInfo();
  }, []);

  useEffect(() => {
    console.log('verificationStatus=>', verificationStatus);
    if (verificationStatus == 'manually_verified') {
      getAccountId();
    }
  }, [verificationStatus]);

  const onSuccess = useCallback((publicToken, metadata) => {
    setLoading(true);
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);
    setPublicToken(publicToken);
    getAccesToken(publicToken); // en que momento debe correr este metodo
    setVerificationStatus(metadata.account.verification_status);
  }, []);

  const onEvent = useCallback((eventName, metadata) => {
    // log onEvent callbacks from Link≥
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const config = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  const handleAccount = (account_id) => {
    setLoading(true);
    handleClose();
    setDisabled(true);
    console.log('account_id=>', account_id);
    setAccountId(account_id);
    getAccountId(account_id);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth={true}
        scroll={'paper'}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          Please click below to link your bank account so you may utilize all the features of Noah’s
          Ark Platform
        </DialogTitle>
        <DialogContent>
          {listAccesToken.map((item, index) => (
            <Card
              key={index}
              onClick={() => handleAccount(item)}
              sx={{
                cursor: 'pointer',
                margin: 2,
                boxShadow: 1, // Ajustar según la sombra deseada
                borderRadius: '10px', // Bordes redondeados
                backgroundColor: '#f5f5f5', // Color de fondo del card
              }}
            >
              <CardContent sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                <Wallet sx={{ fontSize: 40, color: '#424242', marginRight: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 'bold', fontSize: '1rem' }} // Ajustar tamaño de la fuente
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: '#616161' }}
                  >
                    Account : {item.mask}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <Circle sx={{ fontSize: 15, color: green[500], marginRight: 1 }} />
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 'bold', fontSize: '0.875rem', color: '#616161' }} // Ajustar tamaño de la fuente
                    >
                      Connected
                    </Typography>
                  </Box>
                </Box>
                <ArrowForwardIos sx={{ fontSize: 25, color: '#9e9e9e' }} /> {/* Icono más sutil */}
              </CardContent>
            </Card>
          ))}
        </DialogContent>
      </Dialog>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '800px',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Centra horizontalmente
              alignItems: 'center', // Centra verticalmente
              height: '50vh', // Altura de la ventana del navegador
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={open}
                disabled={!ready || disabled}
                sx={{
                  fontSize: 25,
                  margin: 2,
                  backgroundColor: '#e0e0e0', // Color de fondo del botón
                  color: '#000', // Color del texto del botón
                  textTransform: 'none', // Mantiene la capitalización del texto como está
                  borderRadius: '50px', // Bordes redondeados
                  boxShadow:
                    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // Sombra del botón
                  '&:hover': {
                    backgroundColor: '#d5d5d5', // Color de fondo del botón al pasar el mouse
                    // boxShadow for hover can also be adjusted here if needed
                  },
                  padding: '50px 50px', // Ajusta el padding para cambiar el tamaño del botón
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {nameButton === 'Add funding source' ? (
                    <AddCircle sx={{ fontSize: 60, color: '#2979ff' }} /> // El color del ícono puede ajustarse aquí
                  ) : (
                    <VerifiedUserIcon sx={{ fontSize: 60, color: '#2979ff' }} /> // El color del ícono puede ajustarse aquí
                  )}
                  <Typography sx={{ marginLeft: 1 }}>{nameButton}</Typography>
                </Box>
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlaidStep;
