import { FunctionComponent } from 'react';
import ContainerHero from '../components/ContainerHero';
import DonationFormContainer from '../components/DonationFormContainer';
import FundraiserSection1 from '../components/FundraiserSection1';
import FundraiserSection from '../components/FundraiserSection';
// import FeaturedCardContainer from '../components/FeaturedCardContainer';
import CardContainer from '../components/CardContainer';
import CardContainer1 from '../components/CardContainer1';
// import ThankYouSectionContainer from '../components/ThankYouSectionContainer';
// import ContactUsForm from '../components/ContactUsForm';
import Footer from '../components/Footer';

const Home: FunctionComponent = () => {
  return (
    <div className='relative bg-white w-full overflow-hidden flex flex-col items-start justify-start lg:w-auto lg:[align-self:unset] lg:items-center lg:justify-start'>
      <ContainerHero />
      <DonationFormContainer />
      <FundraiserSection1 />
      <FundraiserSection />
      <CardContainer />
      <CardContainer1 />
      <div className='self-stretch h-[120px] overflow-hidden shrink-0 hidden flex-row items-center justify-end md:hidden md:h-[62px] sm:h-auto sm:items-center sm:justify-end'>
        <img
          className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full sm:w-full sm:h-[26px]'
          alt=''
          src='/vector5.svg'
        />
      </div>
      <footer className='self-stretch flex flex-col pt-16 px-0 pb-0 items-center justify-start gap-[64px] md:pt-[50px] md:box-border sm:gap-[64px] sm:pt-[50px] sm:box-border'>
        <Footer />
      </footer>
    </div>
  );
};

export default Home;
