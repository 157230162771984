import React, { FunctionComponent } from "react";
import DonationFormContainer1 from "./DonationFormContainer1";

import "./styles.css";
import { HomeConfig } from "Config/home.config";

const DonationFormContainer: FunctionComponent = () => {
  return (
    <div className="body self-stretch flex flex-row   items-center justify-center text-center text-lgi text-darkslategray font-inter lg:pl-[70px] lg:pr-[70px] lg:box-border">
      <div className="flex-1 flex flex-row flex-wrap items-start justify-center gap-[50px] md:flex-row">
        {HomeConfig.features.map((item, index) => (
          <DonationFormContainer1
            key={index}
            formDimensions={
              <img
                src={item.icon}
                alt={item.title}
                style={{ width: 100 }}
                className="mb-1"
              />
            }
            formType={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default DonationFormContainer;
