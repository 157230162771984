import { newServer } from 'constants/constants';

export const updateEnroll = async (user: any, bodyData: any) => {
  const response = await fetch(`${newServer}requests`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.token}`,
    },
    body: JSON.stringify(bodyData),
  });

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    if (response.status === 200) {
      return response;
    }
    throw new Error(JSON.stringify(error));
  }
};
