import { Grid } from '@mui/material';
import './styles.css';
import Lottie from 'react-lottie';
import animationData from '../../lotties-animations/rejected.json';

export const RejectedEnrolled = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Grid>
      <Lottie
        options={defaultOptions}
        height={250}
        width={250}
      />
      <p className='description'>
        Sorry, you application has been rejected please review your email for more details. You may review your aplication and re submit.
      </p>
    </Grid>
  );
};
