import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogTitle,
  DialogActions,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { Visibility, VisibilityOff, Info, Edit } from '@mui/icons-material';
import {
  states,
  processorsURL,
  formcustomerbeneficial,
  statesAbbreviations,
  ipayNet,
} from '../../../constants/constants';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm } from '../Custom/UseForm';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useEnrollForm } from 'Contexts/EnrollProvider/EnrollProvider';
import { useDialog } from 'Contexts/Modals/ModalProvider';

export default function FormBeneficialOwners(props) {
  const { onClose, open, data } = props;
  console.log(data);
  const [isPassport, setIsPassport] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(moment().subtract(19, 'years').toDate());
  const [selectState, setSelectState] = useState('');
  const [selectCountry, setSelectCountry] = useState('');
  const [validateFormState, setValidateFormState] = useState(true);
  const [validate, setValidate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enrollData, saveEnrrollData, enrollDataUsio, saveEnrrollDataUsio } = useEnrollForm();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { openDialog } = useDialog();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const customHeight = {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 52,
    },
  };

  const {
    formState,
    setFormState,
    onInputChange,
    onResetForm,
    firstName,
    lastName,
    ssn,
    address1,
    address2,
    city,
    postalCode,
    numberPassport,
    countryPassport,
    ownershipPercent,
  } = useForm({
    firstName: '',
    lastName: '',
    ssn: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    ownershipPercent: 0,
    numberPassport: '',
    countryPassport: '',
  });

  const saveBeneficial = async () => {
    const _data = {
      firstName,
      lastName,
      ssn,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      ownershipPercent,
      address: {
        address1,
        address2,
        city,
        postalCode,
        stateProvinceRegion: selectState,
        country: selectCountry,
      },
      passport: {
        number: numberPassport,
        country: countryPassport,
      },
    };

    if (enrollData?.data?.controller?.ownershipPercent) {
      let totalOwnershipPercent = 0;

      if (enrollData.data.controller) {
        totalOwnershipPercent += parseFloat(enrollData.data.controller.ownershipPercent);
      }

      if (enrollData.data.owners) {
        enrollData.data.owners.forEach((owner) => {
          totalOwnershipPercent += parseFloat(owner.ownershipPercent);
        });
      }

      totalOwnershipPercent += Number(_data.ownershipPercent);

      if (totalOwnershipPercent > 100) {
        openDialog('information', null, {
          title: 'Application incomplete',
          icon: <Info />,
          cancelText: 'Ok',
          description: `The total percentage exceeds 100%. It currently totals ${totalOwnershipPercent}%.`,
        });
        return;
      }
    }

    const dataBeneficial = new Map(data.beneficials);
    dataBeneficial.set(_data.ssn, {
      ..._data,
      id: '', //este es el id de dwolla
      status: 'success',
      idImage: '',
    });
    // data.setBeneficials(dataBeneficial);
    data.saveNewBenefitial(_data);
    //saveBeneficialDwolla();
    onClose();
    // data._showMessage("The beneficial owner has been added.", "success");
  };

  const editBeneficial = async () => {
    const _data = {
      firstName,
      lastName,
      ssn,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      ownershipPercent,
      address: {
        address1,
        address2,
        city,
        postalCode,
        stateProvinceRegion: selectState,
        country: selectCountry,
      },
      passport: {
        number: numberPassport,
        country: countryPassport,
      },
    };

    const a = data.beneficials[0].owners.filter((owner) => owner.ssn === _data.ssn);

    //actualizo el owner con el nuevo valor
    const newOwner = a[0];
    newOwner.firstName = _data.firstName;
    newOwner.lastName = _data.lastName;
    newOwner.ssn = _data.ssn;
    newOwner.dateOfBirth = _data.dateOfBirth;
    newOwner.ownershipPercent = _data.ownershipPercent;
    newOwner.address = _data.address;
    newOwner.passport = _data.passport;

    //actualizo el map de beneficials
    const newBeneficials = data.beneficials;
    newBeneficials[0].owners = newBeneficials[0].owners.filter((owner) => owner.ssn !== _data.ssn);
    newBeneficials[0].owners.push(newOwner);
    data.setBeneficials(newBeneficials);

    console.log(enrollData.data.controller.ownershipPercent);

    if (enrollData?.data?.controller?.ownershipPercent) {
      let totalOwnershipPercent = 0;

      if (enrollData.data.controller) {
        totalOwnershipPercent += parseFloat(enrollData.data.controller.ownershipPercent);
      }

      if (enrollData.data.owners) {
        enrollData.data.owners.forEach((owner) => {
          console.log(owner.ownershipPercent);
          totalOwnershipPercent += parseFloat(owner.ownershipPercent);
        });
      }

      if (totalOwnershipPercent > 100) {
        openDialog('information', null, {
          title: 'Application incomplete',
          icon: <Info />,
          cancelText: 'Ok',
          description: `The total percentage exceeds 100%. It currently totals ${totalOwnershipPercent}%.`,
        });
        return;
      }
    }
    onClose();
  };

  const saveBeneficialDwolla = async () => {
    data.setButtonVerify(true);
    const data = {
      firstName,
      lastName,
      ssn,
      ownershipPercent,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
      address: {
        address1,
        address2,
        city,
        postalCode,
        stateProvinceRegion: selectState,
        country: selectCountry,
      },
      passport: {
        number: numberPassport,
        country: countryPassport,
      },
    };

    if (validateFormState) {
      return;
    }

    const response = await fetch(
      `${processorsURL}/api/dwolla/create-beneficial-owner/${data.customerId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Project: ipayNet.project,
          X_API_KEY: ipayNet.X_API_KEY,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.status != 200) {
      data.setButtonVerify(false);
      data._showMessage(await response.text(), 'error');
      return;
    }

    const dataResponse = await response.json();

    const dataBeneficial = new Map(data.beneficials);
    dataBeneficial.set(data.ssn, {
      ...data,
      id: dataResponse.id, //este es el id de dwolla
      status: 'success',
      idImage: '',
    });
    data.setButtonVerify(false);
    data.setBeneficials(dataBeneficial);
    data.handleCloseModal();
    data._showMessage('Verified Beneficial Owner.', 'success');
  };

  const handleInputNumber = (e, max) => {
    if (e?.target?.value?.length === max + 1) {
      return;
    }
    onInputChange(e);
  };

  const handleInputNumberPercent = (e, max) => {
    if (e?.target?.value?.length === max + 1) {
      return;
    }
    onInputChange(e);

    const sumaTotal = sumaPercent(data.beneficials);

    if (sumaTotal + Number(e?.target?.value) > 100) {
      setValidate(true);
      return;
    } else {
      setValidate(false);
    }
    onInputChange(e);
  };

  const sumaPercent = (dataBeneficial) => {
    let sumaOwnershipPercent = 0;
    dataBeneficial.forEach((value, key) => {
      sumaOwnershipPercent += Number(value.controller?.ownershipPercent || value.ownershipPercent);
    });
    return sumaOwnershipPercent;
  };

  const validateForm = () => {
    //false = true enabled
    //true = false disabled
    let validateSSN = false; //if not exist in map
    if (ssn.length == 9 && !data.owner?.ssn) {
      //  validateSSN = data.beneficials?.has(ssn);
    }

    const validate =
      firstName.length > 2 &&
      lastName.length > 2 &&
      postalCode.length == 5 &&
      ownershipPercent <= 100 &&
      ownershipPercent.length > 0 &&
      city.length > 3 &&
      selectState?.length > 3 &&
      address1.length > 3 &&
      ssn.length == 9 &&
      !validateSSN
        ? false
        : true;

    setValidateFormState(validate);
  };

  useEffect(() => {
    validateForm();
  }, [firstName, lastName, postalCode, ownershipPercent, city, selectState, address1, ssn]);

  useEffect(() => {
    data.saveInMap.current = saveBeneficial;
    data.saveInDwolla.current = saveBeneficialDwolla;

    const newState = { ...formState };

    newState.firstName = data.owner?.firstName || '';
    newState.lastName = data.owner?.lastName || '';
    newState.ssn = data.owner?.ssn || '';
    newState.address1 = data.owner?.address?.address1 || '';
    newState.address2 = data.owner?.address?.address2 || '';
    newState.city = data.owner?.address?.city || '';
    newState.postalCode = data.owner?.address?.postalCode || '';
    newState.ownershipPercent = data.owner?.ownershipPercent || '';
    newState.numberPassport = data.owner?.passport?.numberPassport || '';
    newState.countryPassport = data.owner?.passport?.countryPassport || '';

    data.owner?.address?.stateProvinceRegion
      ? setSelectState(data.owner?.address?.stateProvinceRegion)
      : setSelectState('');
    // data.owner?.country ?? setSelectCountry(data.owner.selectCountry || '')
    setFormState(newState);
    setDateOfBirth(moment(data.owner?.dateOfBirth).toDate());

    const ssnBeneficial = document.getElementById('ssnBeneficial');
    if (ssnBeneficial !== null)
      ssnBeneficial.addEventListener(
        'select',
        function () {
          this.selectionStart = this.selectionEnd;
        },
        false
      );

    setSelectState('Texas');
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    const uppercaseValue = event.target.value.toUpperCase();
    if (statesAbbreviations[uppercaseValue]) {
      setSelectState(statesAbbreviations[uppercaseValue]);
    } else {
      setSelectState(newValue);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      // className='container mx-auto px-8 py-8'
    >
      <DialogTitle style={{ display: 'flex' }}>
        <Info style={{ alignContent: 'center', marginRight: '10px', marginTop: 5 }} />
        <div style={{ fontSize: 24 }}>{data.title}</div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Box sx={{ my: 3, mx: 2 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  type={'text'}
                  required
                  value={firstName}
                  sx={customHeight}
                  name='firstName'
                  fullWidth
                  label='First Name'
                  variant='outlined'
                  helperText={firstName.length >= 2 ? '' : formcustomerbeneficial.firstName.message}
                  onChange={onInputChange}
                  color={firstName.length >= 2 ? 'success' : 'error'}
                  error={firstName.length < 2 && firstName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  value={lastName}
                  sx={customHeight}
                  name='lastName'
                  required
                  helperText={lastName.length >= 2 ? '' : formcustomerbeneficial.lastName.message}
                  fullWidth
                  label='Last Name'
                  variant='outlined'
                  onChange={onInputChange}
                  color={lastName.length >= 2 ? 'success' : 'error'}
                  error={lastName.length < 2 && lastName}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Date Of Birth *'
                    value={dateOfBirth}
                    onChange={(e) => {
                      setDateOfBirth(e);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  value={ownershipPercent}
                  onChange={(e) => {
                    handleInputNumberPercent(e, 3);
                  }}
                  name='ownershipPercent'
                  sx={customHeight}
                  fullWidth
                  label='Ownership percentage'
                  required
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  type='number'
                  helperText={
                    ownershipPercent <= 100 && ownershipPercent.length > 0
                      ? ''
                      : formcustomerbeneficial.percent.message
                  }
                  color={
                    ownershipPercent <= 100 && ownershipPercent.length > 0 ? 'success' : 'error'
                  }
                  error={ownershipPercent <= 0 && ownershipPercent}
                  variant='outlined'
                />
              </Grid>

              {isPassport ? (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <TextField
                    value={numberPassport}
                    sx={customHeight}
                    name='numberPassport'
                    type={'number'}
                    fullWidth
                    required
                    helperText={
                      numberPassport.length == 10
                        ? ''
                        : formcustomerbeneficial.numberPassport.message
                    }
                    label='Passport Number'
                    variant='outlined'
                    color={numberPassport.length == 10 ? 'success' : 'error'}
                    onChange={onInputChange}
                    placeholder={''}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <TextField
                    value={ssn}
                    sx={customHeight}
                    name='ssn'
                    required
                    helperText={ssn.length == 9 ? '' : formcustomerbeneficial.ssn.message}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    label='SSN'
                    id='ssnBeneficial'
                    variant='outlined'
                    onChange={(e) => {
                      handleInputNumber(e, 9);
                    }}
                    placeholder={'123456789'}
                    disabled={data.owner?.ssn ? true : false}
                    // color={
                    //   ssn.length == 9 && !data.beneficials.has(ssn)
                    //     ? "success"
                    //     : "error"
                    // }
                    error={ssn?.length <= 8 && ssn}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box sx={{ my: 3, mx: 2 }}>
            <Grid
              container
              spacing={2}
            >
              {isPassport && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <TextField
                    value={countryPassport}
                    sx={customHeight}
                    name='countryPassport'
                    fullWidth
                    label='Country issuing Passport '
                    variant='outlined'
                    required
                    helperText={
                      countryPassport.length >= 3
                        ? ''
                        : formcustomerbeneficial.countryPassport.message
                    }
                    color={countryPassport.length >= 3 ? 'success' : 'error'}
                    onChange={onInputChange}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={isPassport ? 2 : 12}
                lg={isPassport ? 2 : 12}
              >
                <TextField
                  value={address1}
                  sx={customHeight}
                  name='address1'
                  fullWidth
                  label='Address 1'
                  required
                  helperText={address1.length >= 3 ? '' : formcustomerbeneficial.address.message}
                  color={address1.length >= 3 ? 'success' : 'error'}
                  error={address1?.length <= 2 && address1}
                  variant='outlined'
                  onChange={onInputChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  value={address2}
                  sx={customHeight}
                  name='address2'
                  fullWidth
                  label='Address 2 (Optional)'
                  variant='outlined'
                  onChange={onInputChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  value={city}
                  sx={customHeight}
                  name='city'
                  fullWidth
                  label='City'
                  required
                  color={city.length >= 3 ? 'success' : 'error'}
                  variant='outlined'
                  onChange={onInputChange}
                  helperText={city.length >= 3 ? '' : formcustomerbeneficial.city.message}
                  error={city?.length <= 2 && city}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <Autocomplete
                  freeSolo
                  options={states}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'State'}
                      required
                      color={selectState?.length >= 3 ? 'success' : 'error'}
                      onChange={handleAutocompleteChange}
                      error={selectState?.length <= 2 && selectState}
                    />
                  )}
                  value={selectState}
                  onChange={(event, newInputValue) => {
                    setSelectState(newInputValue);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
              >
                <TextField
                  value={postalCode}
                  sx={customHeight}
                  name='postalCode'
                  fullWidth
                  label='Postal Code'
                  required
                  helperText={postalCode.length == 5 ? '' : formcustomerbeneficial.zip.message}
                  error={postalCode?.length <= 4 && postalCode}
                  variant='outlined'
                  type={'number'}
                  onChange={(e) => {
                    handleInputNumber(e, 5);
                  }}
                  color={postalCode.length == 5 ? 'success' : 'error'}
                  placeholder={'12345'}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button
          variant='contained'
          onClick={handleClose}
          style={{ marginRight: '8px', backgroundColor: '#f0f0f0', color: 'black' }}
        >
          Cancel
        </Button>

        <Button
          variant='contained'
          onClick={data.owner ? editBeneficial : saveBeneficial}
          disabled={validateFormState}
          style={{ backgroundColor: '#ff0099', color: 'white' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
